<script>
import Months from "@/core/Months"

export default {
  props: {
    start_year: {
      type: Number,
      default: 2024,
      required: true
    },
    end_year: {
      type: Number,
      default: 2025,
      required: true
    }
  },
  data() {
    return {
      months: Months,
    }
  },
  computed: {
    available_filter_years() {
      const years = [];
      for (let year = this.start_year; year <= this.end_year; year++) {
        years.push(year);
      }
      return years;
    }
  },
  methods: {
    async removeYearFilter() {
      const current_queries = {...this.$route.query};
      delete current_queries.date;
      try {
        await this.$router.push({query: current_queries});
      } catch (error) {
        console.error('Failed to update route:', error);
      }
    },
    async filterByYearAndMonth(year, month) {
      if (month) {
        await this.$router.push({
          query: {
            ...this.$route.query,
            date: `${year}-${month.id < 10 ? `0${month.id}` : month.id}`
          }
        })
      } else {
        await this.$router.push({
          query: {
            ...this.$route.query,
            date: `${year}`
          }
        })
      }
      setTimeout(() => {
        this.closeDropdowns(year)
      }, 50)
    },
    closeDropdowns(year) {
      const openDropdowns = document.querySelectorAll('.dropdown-menu.show');
      openDropdowns.forEach((dropdown) => {
        if ((dropdown.id && dropdown.id) === `filter_by_year_${year}`) {
          return;
        }
        dropdown.classList.remove('show');
      });
    }
  },
}
</script>

<template>
  <div class="d-flex gap-3 mt-3 align-items-center">
    <b-button @click="removeYearFilter()"
              size="sm" :variant="this.$route.query.date ? 'soft-primary' : 'primary'"
    >
      All
    </b-button>
    <div v-for="year in available_filter_years" :key="`year_${year}`"
         class="dropdown" :id="`filter_by_year_${year}`"
    >

      <b-button data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                size="sm"
                :variant="($route.query.date || '').startsWith(year.toString()) ? 'primary' : 'soft-primary'"
      >
        {{ year }}
      </b-button>

      <div class="dropdown-menu dropdown-menu-end" style="z-index: 99999" :id="`filter_by_year_${year}_items`">
        <a @click="filterByYearAndMonth(year)" class="dropdown-item" data-toggle="fullscreen" style="cursor: pointer">
          <span class="align-middle" data-key="t-logout">All in {{ year }}</span>
        </a>
        <a v-for="month in months" :key="`month_${month.id}_year_${year}`"
           class="dropdown-item" style="cursor: pointer"
           :class="{
                 'active bg-soft-primary': (($route.query.date || '') === `${year}-${month.id >= 10 ? month.id : `0${month.id}`}`)
               }"
           @click="filterByYearAndMonth(year, month)"
        >
              <span class="align-middle">
                {{ month.label }}
              </span>
        </a>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>