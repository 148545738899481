<script>
import {defineComponent} from 'vue'
import Swal from "sweetalert2";
import axios from "axios";
import store from "@/state/store";
import Currencies from "@/core/Currencies";

export default defineComponent({
  name: "Counterparty",
  emits: ['update'],
  props: {
    counterparty: {
      type: Object,
      required: true,
      default: () => {
      }
    },
    counterparty_list: {
      type: Array,
      required: true,
      default: () => []
    },
    category_list: {
      type: Array,
      required: true,
      default: () => []
    },
  },
  data() {
    return {
      Currencies: Currencies
    }
  },
  methods: {
    async updateCounterparty(item) {
      const {value: formValues} = await Swal.fire({
        html:
            '<h4 class="my-4 py-3">Update Counterparty</h4>' +
            '<select class="form-select m-auto w-75 mt-3" id="categoryUpdate">' +
            `${this.category_list.map(c => {
              return item.category.id === c.id ? `<option value="${c.id}" selected>${c.name}</option>` : `<option value="${c.id}">${c.name}</option>`
            })}` +
            '</select>' +
            '<select class="form-select m-auto w-75 mt-3" id="counterpartyUpdate">' +
            `${this.counterparty_list.map(c => {
              return item.counterparty.id === c.id ? `<option value="${c.id}" selected>${c.name}</option>` : `<option value="${c.id}">${c.name}</option>`
            })}` +
            '</select>' +
            '<div class="m-auto w-75 mt-3 border-bottom"></div>' +
            '<select class="form-select m-auto w-75 mt-3" id="currencyUpdate">' +
            `${this.Currencies.map(c => {
              return item.currency === c.value
                  ? `<option value="${c.value}" selected>${c.label}</option>`
                  : `<option value="${c.value}">${c.label}</option>`
            })}` +
            '</select>' +
            `<input value='${item.exchange_rate}' id="exchangeRateUpdate" class="form-control m-auto w-75 mt-3" placeholder="Exchange rate" type="number" />` +
            '<div class="mt-3 w-75 m-auto fs-14 text-start">Please enter the exchange rate in the format: how much 1 USD equals in the selected currency</div>',
        focusConfirm: false,
        confirmButtonText: 'Save',
        confirmButtonColor: '#0AB39C',
        preConfirm: () => {
          let exchange_rate_is_valid = document.getElementById('exchangeRateUpdate').value !== "" && document.getElementById('exchangeRateUpdate').value >= 0
          let currency_is_valid = this.Currencies.map(c => c.value).includes(document.getElementById('currencyUpdate').value)
          const category_is_valid = document.getElementById('categoryUpdate').value
          const counterparty_is_valid = document.getElementById('counterpartyUpdate').value

          if (!category_is_valid) {
            return Swal.showValidationMessage(`Please select a category`);
          }

          if (!counterparty_is_valid) {
            return Swal.showValidationMessage(`Please select a counterparty`);
          }

          if (!currency_is_valid) {
            return Swal.showValidationMessage(`Please select a currency`);
          }

          if (!exchange_rate_is_valid) {
            return Swal.showValidationMessage(`Please fill out the exchange rate`);
          }

          return {
            exchange_rate: document.getElementById('exchangeRateUpdate').value,
            currency: document.getElementById('currencyUpdate').value,
            category_id: document.getElementById('categoryUpdate').value,
            counterparty_id: document.getElementById('counterpartyUpdate').value
          }
        }
      })

      console.log(formValues)

      if (formValues) {

        const Toast = Swal.mixin({
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        try {
          await axios.put(`/order/counterparty/update/${item.id}/`, {
            "counterparty_id": formValues.counterparty_id,
            "category_id": formValues.category_id,
            "currency": formValues.currency,
            "exchange_rate": formValues.exchange_rate,
          })
          this.$emit('update')
          await Toast.fire({
            icon: 'success',
            title: 'Successfully updated'
          })
        } catch {
          await Toast.fire({
            icon: 'error',
            title: 'Something went wrong'
          })
        }
      }
    },
  },
  computed: {
    has_access_to_visit_counterparty_profile() {
      return store.state.user.pages.includes('counterparty_profile_main')
    }
  }
})
</script>

<template>
  <div class="dropdown ms-1">
    <div type="button" data-bs-toggle="dropdown">
      <span class="badge bg-success">{{ counterparty.category.name }}</span>
      <span class="d-block">{{ counterparty.counterparty.name }}</span>
    </div>
    <div class="dropdown-menu dropdown-menu-end">
      <a class="dropdown-item cursor-pointer d-flex justify-content-between align-middle py-2"
         @click="updateCounterparty(counterparty)">
        Edit
        <i class="mdi mdi-pencil"></i>
      </a>
      <router-link
          v-if="counterparty.counterparty && counterparty.counterparty.company_slug && has_access_to_visit_counterparty_profile"
          :to="{
            name: 'counterparty_profile_main',
            params: {slug: counterparty.counterparty.company_slug},
            query: {order: this.$route.params.id}
          }"
          class="dropdown-item cursor-pointer d-flex justify-content-between align-bottom py-2">
        Visit Profile
        <font-awesome-icon class="text-secondary" :icon="['fas', 'up-right-from-square']"/>
      </router-link>
    </div>
  </div>
</template>

<style scoped>

</style>