<script>
import {defineComponent} from 'vue'
import {emptyWagonOrdersMethods, emptyWagonOrderComputed} from "@/state/helpers"
import axios from "axios"
import "@vueform/multiselect/themes/default.css";
import Multiselect from "@vueform/multiselect";

export default defineComponent({
  name: "Step4",
  emits: ['created', 'goNextStep', 'goPreviousStep'],
  data() {
    return {
      containerTypes: [
        {id: '20', name: '20'},
        {id: '20HC', name: '20HC'},
        {id: '40', name: '40'},
        {id: '40HC', name: '40HC'},
        {id: '45', name: '45'},
      ],
      taskTypes: [],
      task_type_id: null,
      task_deadline: null,
      create_started: false
    }
  },
  components: {
    Multiselect
  },
  computed: {
    ...emptyWagonOrderComputed,
    autocomplete_order_number: {
      get() {
        return this.$store ?
            this.$store.state.emptyWagonOrder.autocomplete.order_number ?
                this.$store.state.emptyWagonOrder.autocomplete.order_number : null
            : null
      },
      set(value) {
        this.$store.state.emptyWagonOrder.autocomplete.order_number = value
      }
    },
    counterparties: {
      get() {
        return this.$store ? this.$store.state.emptyWagonOrder.counterparties : ''
      },
    },
    request_status: {
      get() {
        return this.$store ? this.$store.state.emptyWagonOrder.request_status : 'initial'
      },
      set(value) {
        this.setRequestStatus(value)
      }
    },
    quantity: {
      get() {
        return this.$store ? this.$store.state.emptyWagonOrder.quantity : ''
      },
      set(value) {
        this.setQuantity(value)
      }
    },
    additional_cost: {
      get() {
        return this.$store ? this.$store.state.emptyWagonOrder.additional_cost : ''
      },
      set(value) {
        this.setAdditionalCost(value)
      }
    },
    agreed_rate: {
      get() {
        return this.$store ? this.$store.state.emptyWagonOrder.agreed_rate : ''
      },
      set(value) {
        this.setAgreedRate(value)
      }
    },
    pre_order: {
      get() {
        return this.$store ? this.$store.state.emptyWagonOrder.pre_order : false
      },
      set(value) {
        this.setPreOrder(value)
      }
    },
    formIsInvalid() {
      let hasCounterparties = this.counterparties.length > 0
      let hasUnfilledPriceInputs = this.quantity === '' ||
          this.additional_cost === '' ||
          this.counterparties.some(counterparty => {
            return counterparty.categories.some(category => {
              return category.price === ''
            })
          })

      return hasCounterparties && hasUnfilledPriceInputs
    },

    totalProfit() {
      try {
        let pre_costs = this.counterparties.map(counterparty => {
          return counterparty.categories.map(category => {
            return category.price ? category.price : 0
          })
        }).flat()

        let pre_costs_total = pre_costs ? pre_costs.reduce((a, b) => parseFloat(a) + parseFloat(b), 0) : 0

        return (((this.agreed_rate + this.additional_cost) - (pre_costs_total)) * this.quantity).toFixed(2)
      } catch {
        return 0
      }
    }
  },
  methods: {
    ...emptyWagonOrdersMethods,
    async createOrder() {
      let counterparties = []
      let applications = []
      let pre_costs = []
      let end_point_url = ''

      this.$store.state.emptyWagonOrder.counterparties.forEach(counterparty => {
        const categories = counterparty.categories;
        const territories = counterparty.territories ? counterparty.territories : [];
        categories.forEach(category => {
          counterparties.push({
            category_id: category.value,
            counterparty_id: counterparty.counterparty.value,
          });
          pre_costs.push({
            category_id: category.value,
            counterparty_id: counterparty.counterparty.value,
            preliminary_cost: category.price,
          });
        });
        if (this.pre_order && this.pre_order.type === 'code_order') {
          applications.push({
            territories: territories.map(t => {
              return {
                id: t.value,
                name: t.label
              }
            }),
            forwarder_id: counterparty.counterparty.value,
          });
        }
      });


      const date = new Date(this.$store.state.emptyWagonOrder.date)

      let order = {
        "order": {
          "lot_number": this.$store.state.emptyWagonOrder.lot_number,
          "date": date.toISOString().split("T")[0],
          "position": this.$store.state.emptyWagonOrder.position,
          "type": this.$store.state.emptyWagonOrder.order_type,
          "shipment_status": this.$store.state.emptyWagonOrder.shipment_status,
          "payment_status": this.$store.state.emptyWagonOrder.payment_status,
          "shipper": this.$store.state.emptyWagonOrder.shipper,
          "consignee": this.$store.state.emptyWagonOrder.consignee,
          "departure_id": this.$store.state.emptyWagonOrder.departure_id,
          "destination_id": this.$store.state.emptyWagonOrder.destination_id,
          "border_crossing": this.$store.state.emptyWagonOrder.border_crossing,
          "conditions_of_carriage": this.$store.state.emptyWagonOrder.conditions_of_carriage,
          "rolling_stock": this.$store.state.emptyWagonOrder.rolling_stock,
          "departure_country": this.$store.state.emptyWagonOrder.departure,
          "destination_country": this.$store.state.emptyWagonOrder.destination,
          "comment": this.$store.state.emptyWagonOrder.comment,
          "company_id": this.$store.state.emptyWagonOrder.company_id,
          "currency": this.$store.state.emptyWagonOrder.currency,
          "exchange_rate": this.$store.state.emptyWagonOrder.exchange_rate,
          "counterparties": counterparties,
        },
        "is_pre_order": this.$store.state.emptyWagonOrder.is_pre_order,
        "additional_cost": this.$store.state.emptyWagonOrder.additional_cost,
        "agreed_rate": this.$store.state.emptyWagonOrder.agreed_rate,
        "quantity": this.$store.state.emptyWagonOrder.quantity,
        "wagon_empty_preliminary_costs": pre_costs,
        "process_header_id": this.task_type_id,
        "due_date": this.task_deadline,
        "inquiry_id": this.$route.query.inquiry_id,
      }

      end_point_url = '/wagon_empty_order/create/'
      if (this.pre_order && this.pre_order.type === 'code_order') {
        order.pre_order_id = this.pre_order.id
        order.applications = applications
        end_point_url = '/pre_order/convert_pre_order/wagon_empty_order/'
      }

      try {
        this.request_status = 'creating'
        let response = await axios.post(end_point_url, order)
        if (this.pre_order.type === 'code_order') {
          this.pre_order.applications = response.data.applications ? response.data.applications : []
        }
        localStorage.setItem('empty_order', response.data.order)
        if (localStorage.getItem('empty_autocompletes')) {
          let exists = JSON.parse(localStorage.getItem('empty_autocompletes')).find(item => item === this.autocomplete_order_number)

          if (!exists) {
            localStorage.setItem('empty_autocompletes',
                JSON.stringify(
                    [
                      this.autocomplete_order_number,
                      ...JSON.parse(localStorage.getItem('empty_autocompletes')),
                    ]
                )
            )
          }
        } else {
          localStorage.setItem('empty_autocompletes', JSON.stringify([this.autocomplete_order_number]))
        }
        this.request_status = 'success'
        this.$emit('created')
      } catch {
        this.request_status = 'error'
      }
    },
    async getTaskProductList() {
      try {
        let response = await axios.get('/task/product/list/')
        this.taskTypes = response.data.map(item => {
          return {
            value: item.id,
            label: item.title
          }
        })
      } catch {
        alert("error")
        this.taskTypes = []
      }
    }
  },
  mounted() {
    this.getTaskProductList()
  }
})
</script>

<template>
  <div class="d-flex flex-lg-row justify-content-between">
    <div>
      <h5>Counterparty Prices</h5>
      <p class="text-muted">
        Please fill all information below
      </p>
    </div>
    <div class="text-end">
      <h5>Total Profit</h5>
      <h3>
        ${{ totalProfit }}
      </h3>
    </div>
  </div>

  <div class="border border-secondary rounded-3 p-3 overflow-hidden mt-2 mb-4"
  >
    <b-row class="mb-0" gutter-y="4">
      <b-col sm="12">
        <div class="d-flex justify-content-between gap-4">
          <div class="w-100">
            <label class="form-label">
              Quantity
              <span class="text-danger">*</span>
            </label>
            <input
                v-if="pre_order && pre_order.type === ''"
                v-model="quantity" type="number" class="form-control" placeholder="Quantity">
            <input
                v-else-if="pre_order && pre_order.type === 'code_order'"
                :value="quantity" readonly type="number" class="form-control" placeholder="Quantity">
          </div>
          <div class="w-100">
            <label class="form-label">
              Agreed Rate
              <span class="text-danger">*</span>
            </label>
            <input
                v-if="pre_order && pre_order.type === ''"
                v-model="agreed_rate" type="number" class="form-control"
                placeholder="Agreed rate">
            <input
                v-else-if="pre_order && pre_order.type === 'code_order'"
                :value="agreed_rate" readonly type="number" class="form-control"
                placeholder="Agreed rate">
          </div>
          <div class="w-100">
            <label class="form-label">
              Additional Cost
              <span class="text-danger">*</span>
            </label>
            <input v-model="additional_cost" type="number" class="form-control"
                   placeholder="Additional cost">
          </div>
        </div>
      </b-col>

      <b-col lg="4" v-for="counterparty in counterparties.filter(c=>c.counterparty!==null)"
             :key="counterparty">
        <div class="border border-dashed border-secondary rounded-3 p-3">
          <h5 class="mb-4">{{ counterparty.counterparty.label }}</h5>
          <b-row gutter-y="3">
            <b-col sm="12" v-for="category in counterparty.categories" :key="category">
              <label class="form-label">
                {{ category.label }}
                <span class="text-danger">*</span>
              </label>
              <input v-model="category.price" type="number" class="form-control"
                     :placeholder="`Price for ${category.label}`"
              >
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </div>

  <b-row class="align-items-center justify-content-between">
    <b-col lg="6">
      <label class="form-label">Select template task <span class="text-muted">(optional)</span> </label>
      <Multiselect v-model="task_type_id" :options="taskTypes"/>
    </b-col>
    <b-col lg="6">
      <label class="form-label">Deadline </label>
      <input v-model="task_deadline" type="date" class="form-control">
    </b-col>
  </b-row>

  <div class="text-end mt-4">
    <b-button v-if="request_status === 'initial'"
              @click="createOrder()" :disabled="formIsInvalid"
              variant="success" class="fs-18 w-100">Create New Order
    </b-button>
    <button v-else type="button" disabled class="btn btn-success btn-load fs-18 w-100">
      Creating New Order...
      <b-spinner class="ms-2"></b-spinner>
    </button>
  </div>
</template>

<style scoped>
.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(30px);
}
</style>