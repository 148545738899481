<script setup>
import {computed, defineProps} from "vue"
import Months from "@/core/Months";

const props = defineProps({
  statistics: {
    type: Array,
    required: true
  }
})

const statistics = computed(() => {
  return (props.statistics || []).map(statistic => {
    const monthLabel = (month_index) => {
      try {
        let month = Months.find(i => i.id === month_index)
        return month.label
      } catch (e) {
        return month_index
      }
    }
    return {
      year: statistic.year,
      month: statistic.month,
      month_label: monthLabel(statistic.month),
      order_count: statistic.order_count || 0,
      positions: (statistic.positions || []).map(position => {
        return {
          position: position.position,
          revenue: position.revenue,
          share_of_revenue: position.share_of_revenue,
          cost_of_sales: position.cost_of_sales,
          target_revenue: position.target_revenue,
          quantity: position.quantity,
          order_count: position.order_count,
        }
      }),
    }
  })
})

const rowPositionsData = (position, key, positions) => {
  try {
    if (position === 'all') {
      return positions.reduce((sum, item) => sum + (parseFloat(item[key]) || 0), 0);
    } else {
      let item = positions.find(i => i.position === position);
      return parseFloat(item[key]) || 0;
    }
  } catch (e) {
    console.log("rowPositionsData")
    console.log(e)
    return 0;
  }
};


const formatPrice = (price) => {
  return parseFloat(price || '0').toLocaleString(undefined, {
    minimumFractionDigits: 2, maximumFractionDigits: 2
  })
}

const targetRevenueDifference = (position, item) => {
  const rev = parseFloat(rowPositionsData(position, 'revenue', item.positions) || '0')
  const targetRev = parseFloat(rowPositionsData(position, 'target_revenue', item.positions) || '0')

  return parseFloat(rev || '0') - parseFloat(targetRev || '0')
}

const targetRevenueDifferenceInPercentage = (position, item) => {
  const rev = parseFloat(rowPositionsData(position, 'revenue', item.positions) || '0')
  const targetRev = parseFloat(rowPositionsData(position, 'target_revenue', item.positions) || '0')

  console.log(rev, targetRev)

  if (targetRev === 0) return 0;

  console.log("HERE")

  return (((rev / targetRev) * 100)).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

const totalStatisticsCount = (position, key) => {
  return statistics.value.reduce((total, item) => {
    return total + parseFloat(rowPositionsData(position, key, item.positions) || 0);
  }, 0);
};

const totalTargetRevenueDifference = (position) => {
  return statistics.value.reduce((total, item) => {
    return total + parseFloat(targetRevenueDifference(position, item) || 0);
  }, 0);
}

const totalTargetRevenueDifferenceInPercentage = (position) => {
  return statistics.value.reduce((total, item) => {
    return total + (parseFloat(targetRevenueDifferenceInPercentage(position, item) || 0) - 100);
  }, 0);
}

</script>

<template>
  <b-row>
    <b-col lg="12">
      <b-card no-body>
        <b-card-header>
          <b-row class="g-4 align-items-center">
            <b-col sm="auto">
              <div>
                <b-card-title class="mb-0 flex-grow-1">Revenue</b-card-title>
              </div>
            </b-col>
          </b-row>
        </b-card-header>
        <b-card-header>
          <b-row class="align-items-center">
            <b-col>
              <ul class="nav nav-tabs-custom card-header-tabs border-bottom-0 justify-content-end" role="tablist">
                <li class="nav-item">
                  <b-link class="nav-link active fw-semibold" data-bs-toggle="tab" href="#positions-all"
                          role="tab">
                    All
                  </b-link>
                </li>
                <li class="nav-item">
                  <b-link class="nav-link fw-semibold" data-bs-toggle="tab" href="#positions-block-train"
                          role="tab">
                    Block train
                  </b-link>
                </li>
                <li class="nav-item">
                  <b-link class="nav-link fw-semibold" data-bs-toggle="tab" href="#positions-multi-modal"
                          role="tab">
                    Multi modal
                  </b-link>
                </li>
                <li class="nav-item">
                  <b-link class="nav-link fw-semibold" data-bs-toggle="tab" href="#positions-rail-forwarder"
                          role="tab">
                    Rail Forwarder
                  </b-link>
                </li>
              </ul>
            </b-col>
          </b-row>
        </b-card-header>
        <b-card-body>
          <div class="tab-content">
            <div class="tab-pane active" id="positions-all" role="tabpanel">
              <div class="table-card gridjs-border-none table-responsive">
                <table role="grid" class="gridjs-table" style="height: auto;">
                  <thead>
                  <tr>
                    <th class="gridjs-th">
                      Period
                    </th>
                    <th class="gridjs-th">
                      <div class="gridjs-th-content">Orders Quantity</div>
                    </th>
                    <th class="gridjs-th">
                      <div class="gridjs-th-content">Revenue</div>
                    </th>
                    <th class="gridjs-th">
                      <div class="gridjs-th-content">Share of revenue</div>
                    </th>
                    <th class="gridjs-th">
                      <div class="gridjs-th-content">Cost of sales</div>
                    </th>
                    <th class="gridjs-th">
                      <div class="gridjs-th-content">Target revenue</div>
                    </th>
                    <th class="gridjs-th">
                      <div class="gridjs-th-content">Difference</div>
                    </th>
                    <th class="gridjs-th text-center">
                      <div class="gridjs-th-content">Differene in percentage</div>
                    </th>
                  </tr>
                  </thead>
                  <tbody class="gridjs-tbody">
                  <template v-for="(item, index) of statistics" :key="index">
                    <tr class="gridjs-tr">
                      <th class="gridjs-th fw-normal">{{ item.month_label }}, {{ item.year }}</th>
                      <td class="gridjs-td fw-normal">{{ item.order_count }}</td>
                      <td class="gridjs-td">
                        $ {{ formatPrice(rowPositionsData('all', 'revenue', item.positions)) }}
                      </td>
                      <td class="gridjs-td">
                        {{ formatPrice(rowPositionsData('all', 'share_of_revenue', item.positions)) }} %
                      </td>
                      <td class="gridjs-td">
                        $ {{ formatPrice(rowPositionsData('all', 'cost_of_sales', item.positions)) }}
                      </td>
                      <td class="gridjs-td">
                        $ {{ formatPrice(rowPositionsData('all', 'target_revenue', item.positions)) }}
                      </td>
                      <td class="gridjs-td">
                        {{ formatPrice(targetRevenueDifference('all', item)) }}
                      </td>
                      <td class="gridjs-td text-center">
                        <span :class="{
                          'text-success': targetRevenueDifferenceInPercentage('all', item) >= 100,
                          'text-danger': targetRevenueDifferenceInPercentage('all', item) < 100,
                        }">
                          {{ formatPrice(targetRevenueDifferenceInPercentage('all', item) - 100) }}%
                          <i :class="{
                            'ri-arrow-right-up-line': targetRevenueDifferenceInPercentage('all', item) >= 100,
                            'ri-arrow-right-down-line': targetRevenueDifferenceInPercentage('all', item) < 100,
                          }"></i>
                        </span>
                      </td>
                    </tr>
                  </template>
                  <tr class="gridjs-tr">
                    <th class="gridjs-th" colspan="1"></th>
                    <th class="gridjs-th" colspan="1">
                      {{ totalStatisticsCount('all', 'order_count') }}
                    </th>
                    <th class="gridjs-th" colspan="1">
                      $ {{ formatPrice(totalStatisticsCount('all', 'revenue')) }}
                    </th>
                    <th class="gridjs-th" colspan="1">
                      {{ formatPrice(totalStatisticsCount('all', 'share_of_revenue') / statistics.length) }}%
                    </th>
                    <th class="gridjs-th" colspan="1">
                      $ {{ formatPrice(totalStatisticsCount('all', 'cost_of_sales')) }}
                    </th>
                    <th class="gridjs-th" colspan="1">
                      $ {{ formatPrice(totalStatisticsCount('all', 'target_revenue')) }}
                    </th>
                    <th class="gridjs-th" colspan="1">
                      $ {{ formatPrice(totalTargetRevenueDifference('all')) }}
                    </th>
                    <th class="gridjs-th text-center" colspan="1">
                      {{ formatPrice(totalTargetRevenueDifferenceInPercentage('all')) }}%
                    </th>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="tab-pane" id="positions-block-train" role="tabpanel">
              <div class="table-card gridjs-border-none table-responsive">
                <table role="grid" class="gridjs-table" style="height: auto;">
                  <thead>
                  <tr>
                    <th class="gridjs-th">
                      Period
                    </th>
                    <th class="gridjs-th">
                      <div class="gridjs-th-content">Orders Quantity</div>
                    </th>
                    <th class="gridjs-th">
                      <div class="gridjs-th-content">Revenue</div>
                    </th>
                    <th class="gridjs-th">
                      <div class="gridjs-th-content">Share of revenue</div>
                    </th>
                    <th class="gridjs-th">
                      <div class="gridjs-th-content">Cost of sales</div>
                    </th>
                    <th class="gridjs-th">
                      <div class="gridjs-th-content">Target revenue</div>
                    </th>
                    <th class="gridjs-th">
                      <div class="gridjs-th-content">Difference</div>
                    </th>
                    <th class="gridjs-th text-center">
                      <div class="gridjs-th-content">Differene in percentage</div>
                    </th>
                  </tr>
                  </thead>
                  <tbody class="gridjs-tbody">
                  <template v-for="(item, index) of statistics" :key="index">
                    <tr class="gridjs-tr">
                      <th class="gridjs-th fw-normal">{{ item.month_label }}, {{ item.year }}</th>
                      <td class="gridjs-td fw-normal">
                        {{ rowPositionsData('block_train', 'order_count', item.positions) }}
                      </td>
                      <td class="gridjs-td">
                        $ {{ formatPrice(rowPositionsData('block_train', 'revenue', item.positions)) }}
                      </td>
                      <td class="gridjs-td">
                        {{ formatPrice(rowPositionsData('block_train', 'share_of_revenue', item.positions)) }} %
                      </td>
                      <td class="gridjs-td">
                        $ {{ formatPrice(rowPositionsData('block_train', 'cost_of_sales', item.positions)) }}
                      </td>
                      <td class="gridjs-td">
                        $ {{ formatPrice(rowPositionsData('block_train', 'target_revenue', item.positions)) }}
                      </td>
                      <td class="gridjs-td">
                        {{ formatPrice(targetRevenueDifference('block_train', item)) }}
                      </td>
                      <td class="gridjs-td text-center">
                        <span :class="{
                          'text-success': targetRevenueDifferenceInPercentage('block_train', item) >= 100,
                          'text-danger': targetRevenueDifferenceInPercentage('block_train', item) < 100,
                        }">
                          {{ formatPrice(targetRevenueDifferenceInPercentage('block_train', item) - 100) }}%
                          <i :class="{
                            'ri-arrow-right-up-line': targetRevenueDifferenceInPercentage('block_train', item) >= 100,
                            'ri-arrow-right-down-line': targetRevenueDifferenceInPercentage('block_train', item) < 100,
                          }"></i>
                        </span>
                      </td>
                    </tr>
                  </template>
                  <tr class="gridjs-tr">
                    <th class="gridjs-th" colspan="1"></th>
                    <th class="gridjs-th" colspan="1">
                      {{ totalStatisticsCount('block_train', 'order_count') }}
                    </th>
                    <th class="gridjs-th" colspan="1">
                      $ {{ formatPrice(totalStatisticsCount('block_train', 'revenue')) }}
                    </th>
                    <th class="gridjs-th" colspan="1">
                      {{ formatPrice(totalStatisticsCount('block_train', 'share_of_revenue') / statistics.length) }}%
                    </th>
                    <th class="gridjs-th" colspan="1">
                      $ {{ formatPrice(totalStatisticsCount('block_train', 'cost_of_sales')) }}
                    </th>
                    <th class="gridjs-th" colspan="1">
                      $ {{ formatPrice(totalStatisticsCount('block_train', 'target_revenue')) }}
                    </th>
                    <th class="gridjs-th" colspan="1">
                      $ {{ formatPrice(totalTargetRevenueDifference('block_train')) }}
                    </th>
                    <th class="gridjs-th text-center" colspan="1">
                      {{ formatPrice(totalTargetRevenueDifferenceInPercentage('block_train')) }}%
                    </th>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="tab-pane" id="positions-multi-modal" role="tabpanel">
              <div class="table-card gridjs-border-none table-responsive">
                <table role="grid" class="gridjs-table" style="height: auto;">
                  <thead>
                  <tr>
                    <th class="gridjs-th">
                      Period
                    </th>
                    <th class="gridjs-th">
                      <div class="gridjs-th-content">Orders Quantity</div>
                    </th>
                    <th class="gridjs-th">
                      <div class="gridjs-th-content">Revenue</div>
                    </th>
                    <th class="gridjs-th">
                      <div class="gridjs-th-content">Share of revenue</div>
                    </th>
                    <th class="gridjs-th">
                      <div class="gridjs-th-content">Cost of sales</div>
                    </th>
                    <th class="gridjs-th">
                      <div class="gridjs-th-content">Target revenue</div>
                    </th>
                    <th class="gridjs-th">
                      <div class="gridjs-th-content">Difference</div>
                    </th>
                    <th class="gridjs-th text-center">
                      <div class="gridjs-th-content">Differene in percentage</div>
                    </th>
                  </tr>
                  </thead>
                  <tbody class="gridjs-tbody">
                  <template v-for="(item, index) of statistics" :key="index">
                    <tr class="gridjs-tr">
                      <th class="gridjs-th fw-normal">{{ item.month_label }}, {{ item.year }}</th>
                      <td class="gridjs-td fw-normal">
                        {{ rowPositionsData('multi_modal', 'order_count', item.positions) }}
                      </td>
                      <td class="gridjs-td">
                        $ {{ formatPrice(rowPositionsData('multi_modal', 'revenue', item.positions)) }}
                      </td>
                      <td class="gridjs-td">
                        {{ formatPrice(rowPositionsData('multi_modal', 'share_of_revenue', item.positions)) }} %
                      </td>
                      <td class="gridjs-td">
                        $ {{ formatPrice(rowPositionsData('multi_modal', 'cost_of_sales', item.positions)) }}
                      </td>
                      <td class="gridjs-td">
                        $ {{ formatPrice(rowPositionsData('multi_modal', 'target_revenue', item.positions)) }}
                      </td>
                      <td class="gridjs-td">
                        {{ formatPrice(targetRevenueDifference('multi_modal', item)) }}
                      </td>
                      <td class="gridjs-td text-center">
                        <span :class="{
                          'text-success': targetRevenueDifferenceInPercentage('multi_modal', item) >= 100,
                          'text-danger': targetRevenueDifferenceInPercentage('multi_modal', item) < 100,
                        }">
                          {{ formatPrice(targetRevenueDifferenceInPercentage('multi_modal', item) - 100) }}%
                          <i :class="{
                            'ri-arrow-right-up-line': targetRevenueDifferenceInPercentage('multi_modal', item) >= 100,
                            'ri-arrow-right-down-line': targetRevenueDifferenceInPercentage('multi_modal', item) < 100,
                          }"></i>
                        </span>
                      </td>
                    </tr>
                  </template>
                  <tr class="gridjs-tr">
                    <th class="gridjs-th" colspan="1"></th>
                    <th class="gridjs-th" colspan="1">
                      {{ totalStatisticsCount('multi_modal', 'order_count') }}
                    </th>
                    <th class="gridjs-th" colspan="1">
                      $ {{ formatPrice(totalStatisticsCount('multi_modal', 'revenue')) }}
                    </th>
                    <th class="gridjs-th" colspan="1">
                      {{ formatPrice(totalStatisticsCount('multi_modal', 'share_of_revenue') / statistics.length) }}%
                    </th>
                    <th class="gridjs-th" colspan="1">
                      $ {{ formatPrice(totalStatisticsCount('multi_modal', 'cost_of_sales')) }}
                    </th>
                    <th class="gridjs-th" colspan="1">
                      $ {{ formatPrice(totalStatisticsCount('multi_modal', 'target_revenue')) }}
                    </th>
                    <th class="gridjs-th" colspan="1">
                      $ {{ formatPrice(totalTargetRevenueDifference('multi_modal')) }}
                    </th>
                    <th class="gridjs-th text-center" colspan="1">
                      {{ formatPrice(totalTargetRevenueDifferenceInPercentage('multi_modal')) }}%
                    </th>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="tab-pane" id="positions-rail-forwarder" role="tabpanel">
              <div class="table-card gridjs-border-none table-responsive">
                <table role="grid" class="gridjs-table" style="height: auto;">
                  <thead>
                  <tr>
                    <th class="gridjs-th">
                      Period
                    </th>
                    <th class="gridjs-th">
                      <div class="gridjs-th-content">Orders Quantity</div>
                    </th>
                    <th class="gridjs-th">
                      <div class="gridjs-th-content">Revenue</div>
                    </th>
                    <th class="gridjs-th">
                      <div class="gridjs-th-content">Share of revenue</div>
                    </th>
                    <th class="gridjs-th">
                      <div class="gridjs-th-content">Cost of sales</div>
                    </th>
                    <th class="gridjs-th">
                      <div class="gridjs-th-content">Target revenue</div>
                    </th>
                    <th class="gridjs-th">
                      <div class="gridjs-th-content">Difference</div>
                    </th>
                    <th class="gridjs-th text-center">
                      <div class="gridjs-th-content">Differene in percentage</div>
                    </th>
                  </tr>
                  </thead>
                  <tbody class="gridjs-tbody">
                  <template v-for="(item, index) of statistics" :key="index">
                    <tr class="gridjs-tr">
                      <th class="gridjs-th fw-normal">{{ item.month_label }}, {{ item.year }}</th>
                      <td class="gridjs-td fw-normal">
                        {{ rowPositionsData('rail_forwarder', 'order_count', item.positions) }}
                      </td>
                      <td class="gridjs-td">
                        $ {{ formatPrice(rowPositionsData('rail_forwarder', 'revenue', item.positions)) }}
                      </td>
                      <td class="gridjs-td">
                        {{ formatPrice(rowPositionsData('rail_forwarder', 'share_of_revenue', item.positions)) }} %
                      </td>
                      <td class="gridjs-td">
                        $ {{ formatPrice(rowPositionsData('rail_forwarder', 'cost_of_sales', item.positions)) }}
                      </td>
                      <td class="gridjs-td">
                        $ {{ formatPrice(rowPositionsData('rail_forwarder', 'target_revenue', item.positions)) }}
                      </td>
                      <td class="gridjs-td">
                        {{ formatPrice(targetRevenueDifference('rail_forwarder', item)) }}
                      </td>
                      <td class="gridjs-td text-center">
                        <span :class="{
                          'text-success': targetRevenueDifferenceInPercentage('rail_forwarder', item) >= 100,
                          'text-danger': targetRevenueDifferenceInPercentage('rail_forwarder', item) < 100,
                        }">
                          {{ formatPrice(targetRevenueDifferenceInPercentage('rail_forwarder', item) - 100) }}%
                          <i :class="{
                            'ri-arrow-right-up-line': targetRevenueDifferenceInPercentage('rail_forwarder', item) >= 100,
                            'ri-arrow-right-down-line': targetRevenueDifferenceInPercentage('rail_forwarder', item) < 100,
                          }"></i>
                        </span>
                      </td>
                    </tr>
                  </template>
                  <tr class="gridjs-tr">
                    <th class="gridjs-th" colspan="1"></th>
                    <th class="gridjs-th" colspan="1">
                      {{ totalStatisticsCount('rail_forwarder', 'order_count') }}
                    </th>
                    <th class="gridjs-th" colspan="1">
                      $ {{ formatPrice(totalStatisticsCount('rail_forwarder', 'revenue')) }}
                    </th>
                    <th class="gridjs-th" colspan="1">
                      {{ formatPrice(totalStatisticsCount('rail_forwarder', 'share_of_revenue') / statistics.length) }}%
                    </th>
                    <th class="gridjs-th" colspan="1">
                      $ {{ formatPrice(totalStatisticsCount('rail_forwarder', 'cost_of_sales')) }}
                    </th>
                    <th class="gridjs-th" colspan="1">
                      $ {{ formatPrice(totalStatisticsCount('rail_forwarder', 'target_revenue')) }}
                    </th>
                    <th class="gridjs-th" colspan="1">
                      $ {{ formatPrice(totalTargetRevenueDifference('rail_forwarder')) }}
                    </th>
                    <th class="gridjs-th text-center" colspan="1">
                      {{ formatPrice(totalTargetRevenueDifferenceInPercentage('rail_forwarder')) }}%
                    </th>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<style scoped>

</style>