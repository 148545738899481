<script>
import moment from "moment";

export default {
  data() {
    return {
      positions: []
    };
  },
  props: {
    total_revenue: {
      type: Number,
      required: true,
      default: 0
    },
    total_cost_of_sales: {
      type: Number,
      required: true,
      default: 0
    },
    total_margin: {
      type: Number,
      required: true,
      default: 0
    },
    share_of_revenue: {
      type: Number,
      required: true,
      default: 0
    },
    quantitiesList: {
      type: Array,
      required: true,
      default: () => []
    },
    positionsList: {
      type: Array,
      required: true,
      default: () => []
    },
    display_margin: {
      type: Boolean,
      required: true,
      default: false
    },
    date_from: {
      type: String,
      required: true,
      default: ''
    },
    date_to: {
      type: String,
      required: true,
      default: ''
    },
  },
  methods: {
    formatPrice(price, remove_minus_sign) {
      if (remove_minus_sign) {
        return parseFloat(Math.abs(price) || '0').toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      }
      return parseFloat(price || '0').toLocaleString(undefined, {
        minimumFractionDigits: 2, maximumFractionDigits: 2
      })
    },
    calculateTargetDifference(position) {
      let total_revenue = position.total_revenue
      let target_revenue = position.target

      if (total_revenue > target_revenue) {
        return total_revenue - target_revenue
      }

      return target_revenue - total_revenue
    },
    targetRevenueDifference(position) {
      let total_revenue = position.total_revenue
      let target_revenue = position.target

      return total_revenue - target_revenue
    },
  },
  computed: {
    isDateRangeInMonth() {
      const startDate = moment(this.date_from);
      const endDate = moment(this.date_to);

      if (!startDate.isValid() || !endDate.isValid()) {
        throw new Error("Invalid date input");
      }

      const startOfMonth = startDate.clone().startOf("month");
      const endOfMonth = startDate.clone().endOf("month");

      if (
          startDate.isSame(startOfMonth, "day") &&
          endDate.isSame(endOfMonth, "day")
      ) {
        return {ok: true, month: startDate.format("MMMM")}; // Return the month name
      }

      return {ok: false};
    },
  },
  watch: {
    positionsList: {
      handler(newVal) {
        if (newVal) {
          this.positions = this.positionsList.map((position) => {
            return {
              ...position,
              target: position.target ? position.target : 0,
              targetIsNegative: this.targetRevenueDifference(position) < 0,
              targetActualDifference: this.targetRevenueDifference(position)
            }
          });
        } else {
          this.positions = [];
        }
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<template>
  <b-row>

    <b-col xl="3">
      <b-card no-body class="border shadow-none">
        <b-card-body>
          <div class="d-flex align-items-center justify-content-between mb-3">
            <h5 class="text-muted text-uppercase fs-16">
              Total revenue
            </h5>
            <h5 class="text-uppercase fs-16">
              $ {{ formatPrice(total_revenue) }}
            </h5>
          </div>
          <b-list-group flush class="mb-0 pb-0">
            <BListGroupItem class="d-flex justify-content-between align-items-center px-0 fs-14">
              Total Cost of Sales <span>$ {{ formatPrice(total_cost_of_sales) }}</span>
            </BListGroupItem>
            <BListGroupItem v-if="display_margin" class="d-flex justify-content-between align-items-center px-0 fs-14">
              Total margin <span>$ {{ formatPrice(total_margin) }}</span>
            </BListGroupItem>
            <BListGroupItem class="d-flex justify-content-between align-items-center px-0 fs-14">
              Share of Revenue <span>{{ formatPrice(share_of_revenue) }}%</span>
            </BListGroupItem>
            <template v-for="(item, index) in quantitiesList" :key="`total_item_${index}`">
              <BListGroupItem class="d-flex justify-content-between align-items-center px-0 fs-14">
                Total {{ index === 0 ? 'Containers' : 'Wagons' }} <span>{{ item }}</span>
              </BListGroupItem>
            </template>
          </b-list-group>
        </b-card-body>
      </b-card>
    </b-col>

    <b-col xl="3" v-for="(position) of positions" :key="`position_${position.label}`">
      <b-card no-body class="border shadow-none">
        <b-card-body class="pb-0">
          <div class="d-flex align-items-center justify-content-between mb-3">
            <h5 class="text-muted text-uppercase fs-16">
              {{ position.label }}
            </h5>
            <h5 v-b-tooltip.hover title="Total revenue" class="text-uppercase fs-16">
              $ {{ formatPrice(position.total_revenue) }}
            </h5>
          </div>
          <b-list-group flush class="mb-0 pb-0">
            <BListGroupItem class="d-flex justify-content-between align-items-center px-0 fs-14">
              Total Cost of Sales <span>$ {{ formatPrice(position.total_cost_of_sales) }}</span>
            </BListGroupItem>
            <BListGroupItem v-if="display_margin" class="d-flex justify-content-between align-items-center px-0 fs-14">
              Total margin <span>$ {{ formatPrice(position.total_margin) }}</span>
            </BListGroupItem>
            <BListGroupItem class="d-flex justify-content-between align-items-center px-0 fs-14">
              Share of Revenue <span>{{ formatPrice(position.share_of_revenue) }}%</span>
            </BListGroupItem>
            <template v-for="(item, index) in position.quantitiesList" :key="`total_item_${index}`">
              <BListGroupItem class="d-flex justify-content-between align-items-center px-0 fs-14">
                Total {{ index === 0 ? 'Containers' : 'Wagons' }} <span>{{ item }}</span>
              </BListGroupItem>
            </template>
            <template v-if="isDateRangeInMonth.ok">
              <BListGroupItem class="d-flex justify-content-between align-items-center px-0 fs-14">
                {{ isDateRangeInMonth.month }} Target Revenue
                <div class="input-group" style="max-width: 250px">
                  <span class="input-group-text">$</span>
                  <input v-model="position.target" type="number" class="form-control"
                         aria-label="Dollar amount (with dot and two decimal places)">
                </div>
              </BListGroupItem>
              <BListGroupItem class="d-flex justify-content-between align-items-center px-0 fs-14">
                Difference +-
                <div>
                  <span>
                    {{ targetRevenueDifference(position) >= 0 ? '+' : '-' }}
                    ${{ formatPrice(targetRevenueDifference(position), true) }}
                  </span>
                  <span class="ms-1 fs-12" :class="{
                    'text-danger': targetRevenueDifference(position) < 0,
                    'text-success': targetRevenueDifference(position) >= 0,
                  }">
                    {{ formatPrice((targetRevenueDifference(position) / position.total_revenue) * 100, true) }}%
                    <i :class="{
                      'ri-arrow-right-down-line': targetRevenueDifference(position) < 0,
                      'ri-arrow-right-up-line': targetRevenueDifference(position) >= 0,
                    }" class="ms-1 align-middle"></i>
                  </span>
                </div>
              </BListGroupItem>
            </template>
          </b-list-group>
        </b-card-body>
      </b-card>
    </b-col>

  </b-row>
</template>
