<template>
  <div style="min-width: 100px">
    <div v-b-tooltip.hover :title="`$ ${(conversion_rate * parseFloat(actual_cost.actual_cost)).toLocaleString(undefined, {
      minimumFractionDigits: 2, maximumFractionDigits: 2
    }) }`"
         class="y position-relative w-75 mx-auto">

<!--      <input v-if="act" class="form-control form-control-sm"-->
      <!--             type="number" placeholder="Actual cost"-->
      <!--             :value="actual_cost.actual_cost"-->
      <!--             readonly-->
      <!--      >-->

      <div v-if="act" class="input-group input-group-sm">
        <input readonly
            :value="actual_cost.actual_cost" type="number" class="form-control form-control-sm">
        <span class="input-group-text">{{ currency_icon }}</span>
      </div>

      <div v-else class="input-group input-group-sm">
        <input
            v-on:keyup.enter="updateCodePrice(actual_cost)"
            v-model="actual_cost.actual_cost" type="number" class="form-control form-control-sm">
        <span class="input-group-text">{{ currency_icon }}</span>
      </div>

      <span v-if="actual_cost.code"
            class="position-absolute top-0 start-100 translate-middle badge rounded-pill"
            :class="{
        'bg-success': actual_cost.code.status === 'completed',
        'bg-warning': actual_cost.code.status === 'checking',
        'bg-secondary': actual_cost.code.status === 'used',
        'bg-danger': actual_cost.code.status === 'canceled',
            }"
      >
        <router-link class="text-light" :to="{name: 'codes_list', query: {
          number: actual_cost.code.number,
          order: this.$route.params.id || 2
        }}">
          {{ actual_cost.code.number }}
        </router-link>
      </span>

      <span v-if="act" class="position-absolute top-0 start-0 translate-middle">
        <VTooltip>
         <router-link
             @click="localStorage.setItem(`contract_${act.contract_slug}`)"
             :to="{name: 'counterparty_profile_contracts_acts',
             params: {
               slug: act.company_slug,
               contract_slug: act.contract_slug,
             },
             query: {
                act: act.slug,
             }
         }"
         >
            <i class="mdi mdi-circle-medium text-info fs-24 align-middle"></i>
         </router-link>
          <template v-slot:popper>
            Act: <span class="fw-bold ms-1">{{ act.name }}</span>
          </template>
        </VTooltip>
      </span>

    </div>
  </div>

</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";

export default {
  name: "ActualCostInput",
  emits: ['update'],
  props: {
    actualCost: {
      type: Object,
    },
    act: {
      type: Object || null,
      required: true
    },
    currency_icon: {
      type: String,
      required: false,
      default: "$"
    },
    conversion_rate: {
      type: Number,
      required: false,
      default: 1
    },
  },
  computed: {
    actual_cost: {
      get() {
        return this.actualCost
      }
    }
  },
  methods: {
    async updateCodePrice(cost) {
      let Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 5000
      });
      try {
        await axios.put(`/wagon_order/expanse/actual_cost/update/${cost.id}/`, {
          "actual_cost": cost.actual_cost
        })
        this.$emit('update')
        await Toast.fire({
          icon: "success",
          title: "Successfully updated Actual cost",
        });
      } catch {
        await Toast.fire({
          icon: "error",
          title: "Could not update Actual cost",
        });
      }
    }
  }
}
</script>

<style scoped>

</style>