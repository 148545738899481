<script setup>
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";
import Months from "@/core/Months";
import axios from "axios";
import {onMounted, reactive, defineEmits, defineProps, computed} from "vue";
import {useRoute, useRouter} from "vue-router";
import moment from "moment/moment";

const props = defineProps({
  is_generating_report: {
    type: Boolean,
    default: false,
    required: true,
  }
})

const emit = defineEmits([
  'year-select',
  'months-select',
  'manager-select',
  'platform-select',
  'generate-report'
]);

const route = useRoute();
const router = useRouter();

onMounted(async () => {
  await loadManagers();

  const currentYear = new moment().year();

  const query = route.query;

  filterValues.year = validateYear(query.year, currentYear);
  filterValues.months = validateMonths(query.months);
  filterValues.manager_ids = validateManagerIds(query.manager_ids);
  filterValues.platforms = validatePlatforms(query.platforms);

  await onYearSelect(filterValues.year)
  await onMonthsSelect(filterValues.months)
  await onManagerSelect(filterValues.manager_ids)
  await onPlatformSelect(filterValues.platforms)
});


// Validation Functions
const validateYear = (year, currentYear) => {
  const yearValue = parseInt(year, 10);
  return yearValue >= 2022 && yearValue <= currentYear ? yearValue : currentYear;
};

const validateMonths = (months) => {
  if (!months) return [];
  const monthArray = months.split(",").map((m) => parseInt(m, 10));
  return monthArray.filter((month) => month >= 1 && month <= 12);
};

const validateManagerIds = (managerIds) => {
  if (!managerIds) return [];
  const idArray = managerIds.split(",").map((id) => parseInt(id, 10));

  // Ensure IDs are valid numbers and exist in the manager options
  return idArray.filter(
      (id) => !isNaN(id) && filterConfigs.managers.options.some((option) => option.value === id)
  );
};


const validatePlatforms = (platforms) => {
  if (!platforms) return [];
  const platformArray = platforms.split(",");
  return platformArray.filter((platform) => ["CA", "AG"].includes(platform));
};

const onYearSelect = async (value) => {
  await router.push({
    query: {
      ...route.query,
      year: value
    }
  })
  emit('year-select', filterValues.year);
}

const onMonthsSelect = async (value) => {
  if ((value || []).length === 0) {
    let query = {...route.query};
    delete query.months
    await router.push({
      query: {...query}
    })
  } else {
    await router.push({
      query: {
        ...route.query,
        months: (value || []).join(',')
      }
    })
  }
  emit('months-select', filterValues.months);
}

const onManagerSelect = async (value) => {
  if ((value || []).length === 0) {
    let query = {...route.query};
    delete query.manager_ids
    await router.push({
      query: {...query}
    })
  } else {
    await router.push({
      query: {
        ...route.query,
        manager_ids: (value || []).join(',')
      }
    })
  }
  emit('manager-select', filterValues.manager_ids);
}

const onPlatformSelect = async (value) => {
  if ((value || []).length === 0) {
    let query = {...route.query};
    delete query.platforms
    await router.push({
      query: {...query}
    })
  } else {
    await router.push({
      query: {
        ...route.query,
        platforms: (value || []).join(',')
      }
    })
  }
  emit('platform-select', filterValues.platforms);
}

let filterValues = reactive({
  year: null,
  manager_ids: [],
  months: [],
  platforms: [],
});

// Reactive configuration for filters
let filterConfigs = reactive({
  year: {
    placeholder: "Select Year",
    options: [
      {value: "2022", label: "2022"},
      {value: "2023", label: "2023"},
      {value: "2024", label: "2024"},
      {value: "2025", label: "2025"},
    ],
    canClear: false,
  },
  managers: {
    searchable: true,
    placeholder: "Select Managers..",
    options: [],
    mode: "tags",
    closeOnSelect: false,
  },
  months: {
    searchable: true,
    placeholder: "Select Months..",
    options: Months.map((i) => {
      return {
        value: i.id,
        label: i.label,
      };
    }),
    mode: "tags",
    closeOnSelect: false,
  },
  platforms: {
    searchable: true,
    placeholder: "Select Platforms..",
    options: [
      {value: "AG", label: "AG"},
      {value: "CA", label: "CA"},
    ],
    mode: "tags",
    closeOnSelect: false,
  },
});

// Load managers dynamically
const loadManagers = async () => {
  try {
    let response = await axios.get("/user/", {
      params: {
        company: "interrail uzbekistan",
      },
    });
    filterConfigs.managers.options = response.data.results.map((item) => {
      return {
        value: item.id,
        label: item.username,
      };
    });
  } catch {
    filterConfigs.managers.options = [];
  }
};

const canGetReports = computed(() => {
  try {
    let year = filterValues.year
    let platforms = filterValues.platforms;
    return year !== null && platforms.length > 0
  } catch {
    return false
  }
})

const emitGenerateReport = async () => {
  if (!canGetReports.value) return;
  emit('generate-report')
}

</script>

<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-col md="6" xl="3">
          <label>
            Year
            <span class="text-danger">*</span>
          </label>
          <Multiselect
              v-model="filterValues.year"
              v-bind="filterConfigs.year"
              :disabled="props.is_generating_report"
              @change="onYearSelect($event)"
              class="custom-multiselect-padding"
          />
        </b-col>
        <b-col md="6" xl="3">
          <label>
            Month(s)
            <span class="text-danger">*</span>
          </label>
          <Multiselect
              v-model="filterValues.months"
              v-bind="filterConfigs.months"
              :disabled="props.is_generating_report"
              @change="onMonthsSelect($event)"
          />
        </b-col>
        <b-col md="6" xl="3">
          <label>
            Managers
            <small class="text-muted">(optional)</small>
          </label>
          <Multiselect
              v-model="filterValues.manager_ids"
              v-bind="filterConfigs.managers"
              :disabled="props.is_generating_report"
              @change="onManagerSelect($event)"
          />
        </b-col>
        <b-col md="6" xl="3">
          <label>
            Platform(s)
            <span class="text-danger">*</span>
          </label>
          <Multiselect
              v-model="filterValues.platforms"
              v-bind="filterConfigs.platforms"
              :disabled="props.is_generating_report"
              @change="onPlatformSelect($event)"
          />
        </b-col>
        <b-col cols="12">
          <b-button @click="emitGenerateReport()" :disabled="!canGetReports || props.is_generating_report"
                    class="mt-3 w-100" variant="primary">
            <template v-if="!props.is_generating_report">
              Get Reports
              <i class="mdi mdi-refresh align-middle fs-14"></i>
            </template>
            <template v-else>
              Loading...
              <i class="mdi mdi-loading mdi-spin align-middle fs-14"></i>
            </template>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<style scoped>
.custom-multiselect-padding {
  padding-top: 13px;
  padding-bottom: 12px;
}
</style>