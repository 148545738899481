<script>
import {CountTo} from "vue3-count-to";
import ManagerInquiresByStatus from "@/views/pages/inquires/components/ManagerInquiresByStatus.vue"
import {INQUIRY_KPI} from "@/core/KPI";

export default {
  components: {
    CountTo,
    ManagerInquiresByStatus
  },
  props: {
    user_kpi_data: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: () => false
    },
    pending_inquires_count: {
      type: Number,
      required: true,
      default: () => 0
    },
    overall_kpi_percentage: {
      type: Number,
      required: true,
      default: () => 0
    },
    start_date: {
      type: [String, null],
      required: false,
      default: () => null
    },
    end_date: {
      type: [String, null],
      required: false,
      default: () => null
    },
  },
  data() {
    return {
      showLearnMoreModal: false,
      INQUIRY_KPI: INQUIRY_KPI
    }
  },
  methods: {
    openModal() {
      this.showLearnMoreModal = true;
    }
  },
  computed: {
    total_inquiries_count() {
      if (this.user_kpi_data) {
        return this.user_kpi_data.total || 0
      }
      return 0
    },
    response_time_kpi() {
      if (this.user_kpi_data) {
        return (this.user_kpi_data.response_time || {}).target || 0
      }
      return 0
    },
    response_time_kpi_details() {
      if (this.user_kpi_data) {
        let details = (this.user_kpi_data.response_time || {}).details || null
        if (details) {
          return {
            A: (details.A || {}).count || 0,
            B: (details.B || {}).count || 0,
            C: (details.C || {}).count || 0,
          }
        }
      }
      return {
        A: 0,
        B: 0,
        C: 0,
      }
    },
    completion_kpi_details() {
      if (this.user_kpi_data) {
        let details = (this.user_kpi_data.completion || {}).details || null
        if (details) {
          return {
            A: (details.A || {}).count || 0,
            B: (details.B || {}).count || 0,
            C: (details.C || {}).count || 0,
          }
        }
      }
      return {
        A: 0,
        B: 0,
        C: 0,
      }
    },
    completion_kpi() {
      if (this.user_kpi_data) {
        return (this.user_kpi_data.completion || {}).target || 0
      }
      return 0
    },
    conversion_kpi() {
      if (this.user_kpi_data) {
        return (this.user_kpi_data.conversion || {}).target || 0
      }
      return 0
    },
    conversion_kpi_count() {
      if (this.user_kpi_data) {
        return (this.user_kpi_data.conversion || {}).count || 0
      }
      return 0
    },
    leads_kpi() {
      if (this.user_kpi_data) {
        return (this.user_kpi_data.leads || {}).target || 0
      }
      return 0
    },
    leads_kpi_actual() {
      if (this.user_kpi_data) {
        return (this.user_kpi_data.leads || {}).actual || 0
      }
      return 0
    }
  },
};
</script>

<template>
  <BModal v-model="showLearnMoreModal" hide-footer
          title="KPI PERCENTAGE BREAKDOWN" class="v-modal-custom" size="xl">

    <div class="d-flex align-items-center gap-1 mb-3">
      <span>From <b>{{ start_date }}</b></span>
      <span>to <b>{{ end_date }}</b></span>
    </div>

    <b-row>
      <b-col md="6">
        <b-card no-body class="shadow-none border">
          <b-card-body class="position-relative">
            <div class="d-flex justify-content-between">
              <div>
                <b class="d-block text-body mb-2">
                  {{ INQUIRY_KPI.response_time.label }}
                </b>
                <p class="mb-0 text-muted">
                  {{ INQUIRY_KPI.response_time.description }}.
                  <b>Weights {{ INQUIRY_KPI.response_time.weight }}%</b>
                </p>

                <h2 class="mt-4 ff-secondary fw-semibold">
                  <count-to :startVal='0' :endVal='response_time_kpi' :duration='5000' :decimals="1"></count-to>
                  %
                  <i class="bx bx-x"></i>
                  {{ INQUIRY_KPI.response_time.weight / 100 }}
                  <i class="mdi mdi-equal"></i>
                  {{ (response_time_kpi * (INQUIRY_KPI.response_time.weight / 100)).toFixed(1) }}
                </h2>

                <p class="mb-0 text-muted">
                  <b>Target: </b>{{ INQUIRY_KPI.response_time.target }}.<br>
                  <i data-bs-toggle="collapse" href="#responseTimeMarking"
                     class="fw-medium cursor-pointer text-decoration-underline">See marking criteria</i>
                </p>


              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-success rounded-circle fs-2">
                  <i class="mdi mdi-camera-timer text-success"></i>
                </span>
                </div>
              </div>
            </div>
            <b-collapse id="responseTimeMarking" class="mt-3">

              <div>
                <div class="d-flex align-items-center gap-2">
                  <label class="fs-16">Points Earned:</label>
                  <div class="mb-2 fs-16 d-flex align-items-center gap-1">

                    <div>
                      <span>{{ response_time_kpi_details.A }}</span>
                      <i class="bx bx-x"></i>
                      <span>3</span>
                    </div>

                    +

                    <div>
                      <span>{{ response_time_kpi_details.B }}</span>
                      <i class="bx bx-x"></i>
                      <span>2</span>
                    </div>

                    +

                    <div>
                      <span>{{ response_time_kpi_details.C }}</span>
                      <i class="bx bx-x"></i>
                      <span>(-1)</span>
                    </div>

                    <i class="mdi mdi-equal mx-1"></i>

                    <span>
                  {{
                        response_time_kpi_details.A * 3 + response_time_kpi_details.B * 2 + response_time_kpi_details.C * (-1)
                      }}
</span>
                  </div>
                </div>
                <div class="d-flex align-items-center gap-2">
                  <label class="fs-16">Max Points Possible:</label>
                  <div class="mb-2 fs-16">

                    <span>
                      {{
                        response_time_kpi_details.A + response_time_kpi_details.B + response_time_kpi_details.C
                      }}
                    </span>
                    <i class="bx bx-x"></i>
                    <span>3</span>

                    <i class="mdi mdi-equal mx-1"></i>

                    <span>
                       {{
                        (response_time_kpi_details.A + response_time_kpi_details.B + response_time_kpi_details.C) * 3
                      }}
                    </span>
                  </div>
                </div>
              </div>

              <b-list-group class="w-100">
                <BListGroupItem
                    v-for="item in INQUIRY_KPI.response_time.grades"
                    :key="`response_time_grading_item_${item.label}`"
                >
                  <div class="float-end text-end">
                    <h6 class="mb-0">{{ item.points }}</h6>
                    <small class="text-muted">Points</small>
                  </div>
                  <div class="d-flex mb-2 align-items-center">
                    <div class="flex-shrink-0 avatar-xs">
                      <div
                          :class="['avatar-title rounded material-shadow', `bg-soft-${item.badgeClass} text-${item.badgeClass}`]">
                        <i class="mdi mdi-clock-outline fs-16"></i>
                      </div>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <h5 class="list-title fs-16 mb-1">{{ item.label }}</h5>
                    </div>
                  </div>
                  <p class="list-text mb-0">
                    {{ item.description }}
                  </p>
                </BListGroupItem>
              </b-list-group>
            </b-collapse>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col md="6">
        <b-card no-body class="shadow-none border">
          <b-card-body class="position-relative">
            <div class="d-flex justify-content-between">
              <div>
                <b class="d-block text-body mb-2">
                  {{ INQUIRY_KPI.completion.label }}
                </b>
                <p class="mb-0 text-muted">
                  {{ INQUIRY_KPI.completion.description }}. Weights {{ INQUIRY_KPI.completion.weight }}%
                </p>
                <h2 class="mt-4 ff-secondary fw-semibold">
                  <count-to :startVal='0' :endVal='completion_kpi' :duration='5000' :decimals="1"></count-to>
                  %
                  <i class="bx bx-x"></i>
                  {{ INQUIRY_KPI.completion.weight / 100 }}
                  <i class="mdi mdi-equal"></i>
                  {{ (completion_kpi * (INQUIRY_KPI.completion.weight / 100)).toFixed(1) }}
                </h2>
                <p class="mb-0 text-muted">
                  <b>Target: </b>{{ INQUIRY_KPI.completion.target }}. <br>
                  <i data-bs-toggle="collapse" href="#completionMarking"
                     class="fw-medium cursor-pointer text-decoration-underline">See marking criteria</i>
                </p>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-success rounded-circle fs-2">
                  <i class="mdi mdi-briefcase-edit-outline text-success"></i>
                </span>
                </div>
              </div>
            </div>
            <b-collapse id="completionMarking" class="mt-3">

              <div>
                <div class="d-flex align-items-center gap-2">
                  <label class="fs-16">Points Earned:</label>
                  <div class="mb-2 fs-16 d-flex align-items-center gap-1">

                    <div>
                      <span>{{ completion_kpi_details.A }}</span>
                      <i class="bx bx-x"></i>
                      <span>3</span>
                    </div>

                    +

                    <div>
                      <span>{{ completion_kpi_details.B }}</span>
                      <i class="bx bx-x"></i>
                      <span>2</span>
                    </div>

                    +

                    <div>
                      <span>{{ completion_kpi_details.C }}</span>
                      <i class="bx bx-x"></i>
                      <span>(-1)</span>
                    </div>

                    <i class="mdi mdi-equal mx-1"></i>

                    <span>
                  {{
                        completion_kpi_details.A * 3 + completion_kpi_details.B * 2 + completion_kpi_details.C * (-1)
                      }}
</span>
                  </div>
                </div>
                <div class="d-flex align-items-center gap-2">
                  <label class="fs-16">Max Points Possible:</label>
                  <div class="mb-2 fs-16">

                    <span>
                      {{
                        completion_kpi_details.A + completion_kpi_details.B + completion_kpi_details.C
                      }}
                    </span>
                    <i class="bx bx-x"></i>
                    <span>3</span>

                    <i class="mdi mdi-equal mx-1"></i>

                    <span>
                       {{
                        (completion_kpi_details.A + completion_kpi_details.B + completion_kpi_details.C) * 3
                      }}
                    </span>
                  </div>
                </div>
              </div>

              <b-list-group class="w-100">
                <BListGroupItem
                    v-for="item in INQUIRY_KPI.completion.grades"
                    :key="`response_time_grading_item_${item.label}`"
                >
                  <div class="float-end text-end">
                    <h6 class="mb-0">{{ item.points || 0 }}</h6>
                    <small class="text-muted">Points</small>
                  </div>
                  <div class="d-flex mb-2 align-items-center">
                    <div class="flex-shrink-0 avatar-xs">
                      <div
                          :class="['avatar-title rounded material-shadow', `bg-soft-${item.badgeClass} text-${item.badgeClass}`]">
                        <i class="mdi mdi-clock-outline"></i>
                      </div>
                    </div>
                    <div class="flex-grow-1 ms-3">
                      <h5 class="list-title fs-15 mb-1">{{ item.label }}</h5>
                    </div>
                  </div>
                  <p class="list-text mb-0">
                    {{ item.description }}
                  </p>
                </BListGroupItem>
              </b-list-group>
            </b-collapse>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col md="6">
        <b-card no-body class="shadow-none border">
          <b-card-body class="position-relative">
            <div class="d-flex justify-content-between">
              <div>
                <b class="text-body mb-2 d-block">{{ INQUIRY_KPI.conversion.label }}</b>
                <p class="mb-0 text-muted">
                  {{ INQUIRY_KPI.conversion.description }}. Weights {{ INQUIRY_KPI.conversion.weight }}%
                </p>
                <div>
                  <h2 class="mt-4 ff-secondary fw-semibold">
                    <count-to :startVal='0' :endVal='conversion_kpi' :duration='5000' :decimals="1"></count-to>
                    %
                    <i class="bx bx-x"></i>
                    {{ INQUIRY_KPI.conversion.weight / 100 }}
                    <i class="mdi mdi-equal"></i>
                    {{ (conversion_kpi * (INQUIRY_KPI.conversion.weight / 100)).toFixed(1) }}
                  </h2>
                </div>
                <p class="mb-0 text-muted">
                  <b>Target: </b>{{ INQUIRY_KPI.conversion.target }}
                </p>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-success rounded-circle fs-2">
                  <i class="mdi mdi-check-decagram text-success"></i>
                </span>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col md="6">
        <b-card no-body class="shadow-none border">
          <b-card-body class="position-relative">
            <div class="d-flex justify-content-between">
              <div>
                <b class="text-body mb-2 d-block">{{ INQUIRY_KPI.leads.label }}</b>
                <p class="mb-0 text-muted">
                  {{ INQUIRY_KPI.leads.description }}. Weights {{ INQUIRY_KPI.leads.weight }}%
                </p>
                <div>
                  <h2 class="mt-4 ff-secondary fw-semibold">
                    <count-to :startVal='0' :endVal='leads_kpi' :duration='5000' :decimals="1"></count-to>
                    %
                    <i class="bx bx-x"></i>
                    {{ INQUIRY_KPI.leads.weight / 100 }}
                    <i class="mdi mdi-equal"></i>
                    {{ (leads_kpi * (INQUIRY_KPI.leads.weight / 100)).toFixed(1) }}
                  </h2>
                </div>
                <p class="mb-0 text-muted">
                  <b>Target: </b>{{ INQUIRY_KPI.leads.target }}
                </p>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                <span class="avatar-title bg-soft-success rounded-circle fs-2">
                  <i class="mdi mdi-account-plus-outline text-success"></i>
                </span>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col sm="12">
        <ManagerInquiresByStatus
            :start_date="start_date"
            :end_date="end_date"
            v-if="showLearnMoreModal"/>
      </b-col>
    </b-row>

  </BModal>
</template>

<style>
@import url('https://fonts.googleapis.com/css2?family=DynaPuff:wght@400..700&display=swap');
</style>