<script>
import {defineComponent} from 'vue'
import axios from "axios";
import Swal from "sweetalert2";

export default defineComponent({
  name: "ActualCost",
  emits: ["update"],
  props: {
    actual_cost: {
      type: String,
      required: true,
      default: '0'
    },
    code: {
      type: [Object, null],
      required: true,
    },
    counterparty_id: {
      type: Number,
      required: true,
    },
    act: {
      type: [Object, null],
      required: true,
    },
    conversion_rate: {
      type: Number,
      required: true,
      default: 1
    },
    currency_icon: {
      type: String,
      required: true,
      default: '$'
    },
  },
  data() {
    return {
      new_actual_cost: this.actual_cost,
    }
  },
  methods: {
    async updateActualCost() {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })

      try {
        await axios.put(`/container_order/expanse/actual_cost/update/${this.counterparty_id}/`, {
          actual_cost: this.new_actual_cost
        })
        this.$emit('update')
        await Toast.fire({
          icon: 'success',
          title: 'Actual Cost Updated',
        })
      } catch {
        await Toast.fire({
          icon: 'error',
          title: 'Something went wrong',
        })
      }
    }
  }
})
</script>

<template>
  <div class="m-auto px-2" style="min-width: 100px">
    <div v-b-tooltip.hover :title="`$ ${(parseFloat(actual_cost || '0') * conversion_rate).toLocaleString(undefined, {
      minimumFractionDigits: 2, maximumFractionDigits: 2
    })}`"
         class="y position-relative w-75 mx-auto">

      <div v-if="act" class="input-group input-group-sm">
        <input :value="new_actual_cost" type="text" class="form-control form-control-sm" readonly>
        <span class="input-group-text">{{ currency_icon }}</span>
      </div>

      <div v-else class="input-group input-group-sm">
        <input @keyup.enter="updateActualCost()" v-model="new_actual_cost" type="text" class="form-control form-control-sm"
               aria-label="Dollar amount (with dot and two decimal places)"
        >
        <span class="input-group-text">{{ currency_icon }}</span>
      </div>

      <span v-if="code" class="position-absolute top-0 start-100 translate-middle badge rounded-pill"
            :class="{
        'bg-success': code.status === 'completed',
        'bg-secondary': code.status === 'used',
        'bg-danger': code.status === 'cancelled',
        'bg-warning': code.status === 'checking',
            }"
      >
        <router-link class="text-light" :to="{name: 'codes_list', query: {
          number: code.number,
          order: this.$route.params.id
        }}">
          {{ code.number }}
        </router-link>
      </span>

      <span v-if="act" class="position-absolute top-0 start-0 translate-middle">
        <VTooltip>
         <router-link
             @click="localStorage.setItem(`contract_${act.contract_slug}`)"
             :to="{name: 'counterparty_profile_contracts_acts',
             params: {
               slug: act.company_slug,
               contract_slug: act.contract_slug,
             },
             query: {
                act: act.slug,
             }
         }"
         >
            <i class="mdi mdi-circle-medium text-info fs-24 align-middle"></i>
         </router-link>
          <template v-slot:popper>
            Act: <span class="fw-bold ms-1">{{ act.name }}</span>
          </template>
        </VTooltip>
      </span>

    </div>
  </div>
</template>

<style scoped>

</style>