<script>
import {defineComponent} from 'vue'
import axios from "axios";
import Swal from "sweetalert2";
import skeleton from "@/components/custom/skeleton.vue";
import Tabs from './Tabs.vue'
import OrderActions from "./OrderActions.vue";
import OrdersApi from "@/api/orders/orders_api";
import InvoiceListCard from "./components/InvoiceListCard.vue";
import OrderSMGS from "@/views/pages/orders/components/OrderSMGS.vue";
import Currencies from "@/core/Currencies";

export default defineComponent({
  name: "Index",
  components: {
    OrderSMGS,
    skeleton,
    Tabs,
    OrderActions,
    InvoiceListCard
  },
  data() {
    return {
      order: {},
      loading: true,

      counterparty_list: [],
      category_list: [],

      Currencies: Currencies
    }
  },
  provide() {
    return {
      is_closed: () => this.order_is_closed
    }
  },
  computed: {
    order_is_closed() {
      return this.order ? this.order[0].order.is_closed || false : false
    },
    order_currency() {
      return this.order ? this.order[0].order.currency || 'USD' : 'USD'
    },
    order_exchange_rate() {
      return this.order ? this.order[0].order.exchange_rate || 1 : 1
    },
    order_number() {
      return this.order[0].order.order_number
    },
    order_position() {
      return this.order[0].order.position
    },
    order_type() {
      return this.order[0].order.type
    },
    shipment_status() {
      return this.order[0].order.shipment_status
    },
    payment_status() {
      return this.order[0].order.payment_status
    },
    order_counterparties() {
      return this.order[0].order.counterparties
    },
    container_types() {
      return this.order[0].container_types
    },
    actual_costs() {
      let actual_costs = []
      this.container_types.forEach(type => {
        type.expanses.forEach(expanse => {
          expanse.actual_costs.filter(cost => cost.code).forEach(cost => {
            actual_costs.push({
              ...cost,
              container: expanse.container
            })
          })
        })
      })
      return actual_costs
    },
    container_types_keys_for_order_actions() {
      if (this.order[0].container_types) {
        return this.order[0].container_types.map(c => {
          return {
            id: c.id,
            type: c.container_type,
            quantity: c.quantity,
          }
        })
      }
      return []
    },
    lot_number() {
      return this.order[0].order.lot_number
    },
    sending_type() {
      return this.order[0].sending_type
    },
    company() {
      return this.order[0].order.company
    },
    product() {
      return this.order[0].product
    },
    departure() {
      return this.order[0].order.departure
    },
    destination() {
      return this.order[0].order.destination
    },
    create_date() {
      return this.order[0].order.date
    },
    manager() {
      return this.order[0].order.manager
    },
    comment() {
      return this.order[0].order.comment
    },
    order_invoices() {
      return this.order[0].order.invoices ? this.order[0].order.invoices : []
    },
    order_currency_icon() {
      try {
        let order_currency = this.order ? this.order[0].order.currency || 'USD' : 'USD'
        let currency = this.Currencies.find(i => i.value === order_currency)
        return currency.icon
      } catch {
        return '-$-'
      }
    },
    currency_conversion_rate() {
      try {
        let order_exchange_rate = this.order
            ? this.order[0].order.exchange_rate > 0 ? this.order[0].order.exchange_rate : 1
            : 1
        return parseFloat(1 / parseFloat(order_exchange_rate || '1')).toFixed(2)
      } catch {
        return 1
      }
    },
  },
  methods: {
    tryNewUI() {
      localStorage.setItem('order_detail_ui', 'new')
      localStorage.removeItem('trying_new_ui_first_time')
      window.location.reload()
    },
    capitalizeWords(str) {
      try {
        return str.split('_').map(function (word) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        }).join(' ');
      } catch {
        return '--'
      }
    },
    async fetchData() {
      try {
        let response = await axios.get(`/container_order/list/${this.$route.params.id}/`)
        let data = response.data
        this.order = data
        // this.order = data[0]['order']
        // this.product = data[0]['product']
        // this.container_types = data[0]['container_types']
        // this.sending_type = data[0]['sending_type']
        // this.invoice_list = data[0]['order']['invoices']
        // this.sumAgreedCosts()
        // this.sumProfits()
        this.loading = false
      } catch {
        let timerInterval
        Swal.fire({
          title: 'We could not process your request!',
          icon: 'error',
          timer: 5000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
          },
          willClose: () => {
            clearInterval(timerInterval)
          }
        }).then((result) => {
          if (result) {
            this.$router.push({path: '/orders/'})
          }
        })
      }
    },
    async getCounterpartyList() {
      if (this.counterparty_list.length > 0) return
      let orders = new OrdersApi()
      this.counterparty_list = (await orders.getCounterpartyList()).results
    },
    async getCategoryList() {
      if (this.category_list.length > 0) return;
      let orders = new OrdersApi()
      this.category_list = (await orders.getCategoryList()).results
    },
    invoiceDeleted(deleted_invoice) {
      this.order[0].order.invoices = this.order[0].order.invoices.filter(invoice => invoice.id !== deleted_invoice.id)
    }
  },
  created() {
    this.loading = true
    this.getCounterpartyList()
    this.getCategoryList()
    this.fetchData()
  },
  watch: {
    '$route.params.id': function (newVal) {
      if (!newVal) return
      this.loading = true
      this.fetchData()
    }
  }
})
</script>

<template>
  <div class="container-fluid p-0 mt-0" v-if="loading">
    <div class="row">
      <div class="col-lg-12">
        <div class="card mt-n4 mx-n4 mb-n5">
          <div class="bg-soft-success">
            <div class="card-body pb-4 mb-5">
              <div class="row">
                <div class="col-md">
                  <div class="row align-items-center">
                    <div class="col-md-auto">
                      <div class="avatar-md mb-md-0 mb-4">
                        <div class="avatar-title bg-white rounded-circle">
                          <img
                              src="https://static.wixstatic.com/media/db110e_21c6b16c1b524da3b794f2ed58cf9441~mv2.png/v1/fill/w_265,h_265,al_c,usm_0.66_1.00_0.01/Logistics%20Icon.png"
                              alt="" class="avatar-md">
                        </div>
                      </div>
                    </div>
                    <div class="col-md">
                      <h4 class="fw-semibold">
                        We are processing your order...
                      </h4>
                      <div class="hstack gap-3 flex-wrap">
                        <div class="text-muted" style="min-width: 115px">
                          <span class="align-middle d-inline">
                            <skeleton class="w-100"/>
                          </span>
                        </div>
                        <div class="vr"></div>
                        <div class="text-muted" style="min-width: 95px">
                          <skeleton class="w-100"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xxl-9">
        <div class="card">
          <div class="card-body p-4 pt-2">

            <!-- TAB STARTS -->
            <ul class="nav nav-tabs nav-tabs-custom nav-success nav-justified mb-3" role="tablist">
              <li class="nav-item" role="presentation">
                <a class="nav-link active" data-bs-toggle="tab" href="#actual_cost_tab"
                   role="tab" aria-selected="false" tabindex="-1"> ACTUAL COST </a>
              </li>
              <li class="nav-item" role="presentation">
                <a class="nav-link" data-bs-toggle="tab" href="#preliminary_cost_tab"
                   role="tab" aria-selected="false" tabindex="-1"> PRELIMINARY COST
                </a>
              </li>
            </ul>
            <!-- TAB ENDS -->

            <div class="tab-content text-muted">
              <div class="tab-pane active" id="actual_cost_tab" role="tabpanel">

                <div class="table-responsive table-card">
                  <table class="table table-striped">
                    <thead>
                    </thead>
                  </table>
                </div>

              </div>
              <div class="tab-pane" id="preliminary_cost_tab" role="tabpanel">
                <div class="table-responsive table-card">
                  <table class="table table-striped">
                    <thead class="table-light">
                    </thead>
                  </table>
                </div>
              </div>
            </div>
            <!-- TAB CONTENT ENDS -->
          </div>
        </div>
      </div>
      <div class="col-xxl-3">
        <div class="card">
          <div class="card-header"><h5 class="card-title mb-0">Order Details</h5></div>
          <div class="card-body">
            <div class="table-responsive table-card">
              <table class="table table-borderless align-middle mb-0">
                <tbody>
                <tr>
                  <td class="fw-medium">Lot number</td>
                  <td style="min-width: 125px">
                    <skeleton class="w-100"/>
                  </td>
                </tr>
                <tr>
                  <td class="fw-medium">Sending type</td>
                  <td style="min-width: 125px">
                    <skeleton class="w-100"/>
                  </td>
                </tr>
                <tr>
                  <td class="fw-medium">Product</td>
                  <td style="min-width: 125px">
                    <skeleton class="w-100"/>
                  </td>
                </tr>
                <tr>
                  <td class="fw-medium">Type</td>
                  <td style="min-width: 125px">
                    <skeleton class="w-100"/>
                  </td>
                </tr>
                <tr>
                  <td class="fw-medium">Departure</td>
                  <td style="min-width: 125px">
                    <skeleton class="w-100"/>
                  </td>
                </tr>
                <tr>
                  <td class="fw-medium">Destination</td>
                  <td style="min-width: 125px">
                    <skeleton class="w-100"/>
                  </td>
                </tr>
                <tr>
                  <td class="fw-medium">Create Date</td>
                  <td style="min-width: 125px">
                    <skeleton class="w-100"/>
                  </td>
                </tr>
                <tr>
                  <td class="fw-medium">Comment</td>
                  <td style="min-width: 125px">
                    <skeleton class="w-100"/>
                  </td>
                </tr>
                </tbody>
              </table>

              <button
                  class="btn btn-soft-success fw-medium w-100"
                  type="button"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasRight"
                  aria-controls="offcanvasRight"
              >See Actions
              </button>


            </div>
          </div>
        </div>
      </div>
    </div>

  </div>

  <div class="container-fluid p-0 mt-0" v-else>
    <div class="row">
      <div class="col-lg-12">
        <div class="card mt-n4 mx-n4 mb-n5">
          <div class="bg-soft-success">
            <div class="card-body pb-4 mb-5">
              <div class="row align-items-center">
                <div class="col-md">
                  <div class="row align-items-center">
                    <div class="col-md-auto">
                      <div class="avatar-md mb-md-0 mb-4">
                        <div class="avatar-title bg-white rounded-circle">
                          <img
                              src="https://static.wixstatic.com/media/db110e_21c6b16c1b524da3b794f2ed58cf9441~mv2.png/v1/fill/w_265,h_265,al_c,usm_0.66_1.00_0.01/Logistics%20Icon.png"
                              alt="" class="avatar-md">
                        </div>
                      </div>
                    </div>
                    <div class="col-md">
                      <h4 class="fw-semibold">
                        Order number - {{ order_number }}
                      </h4>
                      <div class="hstack gap-3 flex-wrap">
                        <div class="text-muted text-capitalize"><i
                            class="ri-building-line align-bottom me-1"></i>
                          {{ order_position.replace('_', ' ') }}
                        </div>
                        <div class="vr"></div>
                        <div class="text-muted">Order type :
                          <span class="text-capitalize">{{ order_type }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md text-end">
                  <div class="d-flex justify-content-end gap-4">
                    <button @click="tryNewUI()" v-b-tooltip.hover title="Try new user interface" class="button me-4">
                      ✨ New UI
                    </button>
                    <router-link v-if="order_is_closed !== true"
                                 :to="{name: 'orders_container_update', params: {id: order_number}}">
                      <b-button variant="outline-dark" size="lg">
                        Edit Order
                        <i class="ri-pencil-fill align-bottom ms-1"></i>
                      </b-button>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xxl-9">
        <div class="card">
          <div class="card-body p-4 pt-2">
            <Tabs
                @update="fetchData()"
                :container_types="container_types"
                :order_counterparties="order_counterparties"
                :counterparty_list="counterparty_list"
                :category_list="category_list"
                :order_currency="order_currency"
                :order_currency_icon="order_currency_icon"
                :currency_conversion_rate="currency_conversion_rate"
                :currencies="Currencies"
            />
          </div>
        </div>
      </div>
      <div class="col-xxl-3">
        <div class="card">
          <div class="card-header"><h5 class="card-title mb-0">Order Details</h5></div>
          <div class="card-body pt-1">
            <div class="table-responsive mb-0">
              <table class="table">
                <tbody>
                <tr class="align-middle">
                  <th scope="row">Order number</th>
                  <td class="text-end"><span class="badge badge-soft-primary text-primary">{{ order_number }}</span>
                  </td>
                </tr>
                <tr class="align-middle">
                  <th scope="row">Lot number</th>
                  <td class="text-end">{{ lot_number }}</td>
                </tr>
                <tr class="align-middle">
                  <th scope="row">Sending type</th>
                  <td class="text-end">{{ capitalizeWords(sending_type) }}</td>
                </tr>
                <tr class="align-middle">
                  <th scope="row">Company</th>
                  <td class="text-end">
                    <router-link v-if="company" :to="{name: 'customer_profile', params: {
                      slug: company.slug
                    }}">
                      {{ company.name }}
                    </router-link>
                  </td>
                </tr>
                <tr class="align-middle">
                  <th scope="row">Product</th>
                  <td class="text-end">{{ product ? product.name + ` (${product.hc_code})` : '--' }}</td>
                </tr>
                <tr class="align-middle">
                  <th scope="row">Type</th>
                  <td class="text-end">{{ order_type }}</td>
                </tr>
                <tr class="align-middle">
                  <th scope="row">Departure</th>
                  <td class="text-end">{{ departure ? departure.name + ` (${departure.code})` : '--' }}</td>
                </tr>
                <tr class="align-middle">
                  <th scope="row">Destination</th>
                  <td class="text-end">{{ destination ? destination.name + ` (${destination.code})` : '--' }}</td>
                </tr>
                <tr class="align-middle">
                  <th scope="row">Shipment status</th>
                  <td class="text-end text-capitalize">{{ shipment_status }}</td>
                </tr>
                <tr class="align-middle">
                  <th scope="row">Payment status</th>
                  <td class="text-end text-capitalize">{{ payment_status }}</td>
                </tr>
                <tr v-if="manager" class="align-middle">
                  <th scope="row">Manager</th>
                  <td class="text-end">
                    <router-link v-if="manager && manager.slug"
                                 :to="{name: 'user_profile', params: {slug: manager.slug}}"
                    >
                      {{ manager.username }}
                    </router-link>
                  </td>
                </tr>
                <tr class="align-middle">
                  <th scope="row">Date Created</th>
                  <td class="text-end">{{ create_date }}</td>
                </tr>
                <tr class="align-middle">
                  <th scope="row">Currency</th>
                  <td class="text-end">{{ order_currency }} ({{ order_currency_icon }})</td>
                </tr>
                <tr class="align-middle">
                  <th scope="row">Exchange rate</th>
                  <td v-b-tooltip.hover :title="`$ 1 = ${order_currency_icon} ${order_exchange_rate}`" class="text-end">
                    {{ order_exchange_rate }}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>

            <p>{{ comment }}</p>

            <OrderActions
                :order_number="order_number"
                :counterparties="order_counterparties"
                :container_types="container_types_keys_for_order_actions"
                :counterparty_list="counterparty_list"
                :category_list="category_list"
                :actual_costs="actual_costs"
                @updatedContainers="fetchData()"
                @update="fetchData()"
            />
          </div>
        </div>
        <InvoiceListCard
            :order_number="order_number"
            :order_invoices="order_invoices"
            @invoiceDeleted="invoiceDeleted"
        />
        <OrderSMGS
            :order_number="order_number"
        />
      </div>
    </div>
  </div>
</template>

<style scoped>
.button {
  z-index: 2;
  display: block;
  width: fit-content;
  height: auto;
  outline: none;
  border: none;
  background-color: inherit;
  font-size: 18px;
  font-weight: bold;
  padding: 10px 20px;
  position: relative;
  cursor: pointer;
}

.button::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  z-index: 3;
  position: absolute;
  top: 0%;
  left: 0%;
  transform: scaleX(0.2) scaleY(0.5) translate(250%, 100%);
  border-top: solid 2px #333;
  border-left: solid 4px #333;
  transition: all .4s ease-in-out;
}

.button::after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  z-index: 3;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-50%, -50%) scaleX(0.2) scaleY(0.5);
  border-bottom: solid 2px #333;
  border-right: solid 4px #333;
  transition: all .4s ease-in-out;
}

.button:hover::before {
  transform: translate(0%, 0%) scaleX(1) scaleY(1);
  border-top: solid 1px #333;
  border-left: solid 1px #333;
}

.button:hover::after {
  transform: scaleX(1) scaleY(1) translate(0%, 0%);
  border-bottom: solid 1px #333;
  border-right: solid 1px #333;
}
</style>