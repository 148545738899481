<script>
import Table from "@/components/tables/table.vue";
import axios from "axios";
import Swal from "sweetalert2";
import store from "@/state/store"
import FilterByYearComponent from "@/views/pages/orders/components/FilterByYearComponent.vue";

export default {
  name: "OrdersList",
  emits: ['performedOrderClosing'],
  components: {
    Table,
    FilterByYearComponent
  },
  data() {
    return {
      user: store.state.user,
      ORDER_CHILD_TYPES: {
        container_order: {
          route_name: 'orders_container_detail',
          label: 'Container order'
        },
        wagon_order: {
          route_name: 'orders_wagon_detail',
          label: 'Wagon order'
        },
        wagon_empty_order: {
          route_name: 'orders_empty_wagon_detail',
          label: 'Empty Wagon order'
        },
      },

      POSITION: {
        multi_modal: 'Multi modal',
        rail_forwarder: 'Rail forwarder',
        block_train: 'Block train'
      },
      table: {
        name: 'System Orders List',
        url: '/order/list_by_status/',
        selectable: true,
        headers: [
          {
            field: 'status',
            label: 'Status',
            align: 'center',
            maxWidth: '200px',
            searchType: 'select',
            searchOptions: [
              {label: 'All', value: ''},
              {label: 'Closed', value: 'closed'},
              {label: 'Not closed', value: 'unclosed'},
              {label: 'Missing Invoice', value: 'missing_invoice'},
              {label: 'Unpaid Invoice', value: 'unpaid_invoice'},
              {label: 'Rate Mismatch', value: 'rate_mismatch'},
              {label: 'Quantity Mismatch', value: 'quantity_mismatch'},
              {label: 'Ready to Close Orders', value: 'closeable_orders'},
            ],
          },
          {
            field: 'order_number',
            label: "Order number",
            align: 'center'
          },
          {
            field: 'date',
            label: 'Date',
            align: 'center'
          },
          {
            field: 'lot_number',
            label: 'Lot number',
            align: 'center'
          },
          {
            field: 'position',
            label: 'Position',
            align: 'center',
            searchType: 'select',
            searchOptions: [
              {label: 'All', value: ''},
              {label: 'Multi modal', value: 'multi_modal'},
              {label: 'Rail forwarder', value: 'rail_forwarder'},
              {label: 'Block train', value: 'block_train'},
            ],
          },
          {
            field: 'child_type',
            label: 'Order type',
            align: 'center',
            searchType: 'select',
            searchOptions: [
              {label: 'All', value: ''},
              {label: 'Container order', value: 'container_order'},
              {label: 'Wagon order', value: 'wagon_order'},
              {label: 'Empty Wagon order', value: 'wagon_empty_order'},
            ],
          },
          {
            field: 'total_agreed_rate',
            label: 'Agreed rate',
            align: 'center',
          },
          {
            field: 'invoice',
            label: 'Invoice',
            align: 'center',
          },
          {
            field: 'quantity',
            label: 'Quantity',
            align: 'center'
          },
          {
            field: 'company',
            label: 'Company',
            maxWidth: '200px',
            align: 'center'
          },
          {
            field: 'finance_status',
            label: 'Finance status',
            align: 'center',
            searchType: 'select',
            searchOptions: [
              {label: 'All', value: ''},
              {label: 'Completed', value: 'true'},
              {label: 'Incomplete', value: 'false'},
            ],
            searchable: false
          },
          {
            field: 'type',
            label: 'Type',
            align: 'center',
            searchType: 'select',
            searchOptions: [
              {label: 'All', value: ''},
              {label: 'Import', value: 'import'},
              {label: 'Export', value: 'export'},
              {label: 'Transit', value: 'transit'},
            ],
          },
          {
            field: 'user',
            label: 'Manager',
            align: 'center'
          },
        ],
      },

      closeable_orders_count: 0,
    }
  },
  methods: {
    async onTableLoaded(table_data) {
      if (this.$refs.table) {
        this.$refs.table.apiData = (table_data || []).map(row => {
          let {isClosable, reasons} = this.orderCanBeClosed(row)
          return {
            ...row,
            closeStatus: {
              isClosed: row.is_closed || false,
              isClosable: isClosable,
              reasons: row.is_closed ? ['Order is closed'] : reasons,
              reasons_in_text_format: row.is_closed ? 'Order is closed' : reasons.join(',<br>')
            }
          }
        })
      }
    },
    downloadFile(file) {
      if (!file) return alert("Invalid File")
      let url = process.env.VUE_APP_ORDER_URL + file
      fetch(url)
          .then(resp => resp.blob())
          .then(blobobject => {
            const blob = window.URL.createObjectURL(blobobject);
            const anchor = document.createElement('a');
            anchor.style.display = 'none';
            anchor.href = blob;
            anchor.download = url.toString().split('/')[url.toString().split('/').length - 1];
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(blob);
          })
          .catch(() => alert('An error in downloading the file sorry'));
    },
    orderCanBeClosed(order) {
      let hasInvoice = !!order.invoice;
      let invoiceIsPaid = hasInvoice ? order.invoice.status.toLowerCase() === 'paid' : false;
      let invoicePriceEqualsAgreedRate = hasInvoice ? order.total_agreed_rate === order.invoice.total_cost : false;
      let quantityEqualsFilledQuantity = order.quantity === order.filled_quantity;

      let isClosable = true
      let reasons = []

      if (!quantityEqualsFilledQuantity) {
        isClosable = false
        reasons.push('Order quantity does not match filled quantity')
      }

      if (!hasInvoice) {
        isClosable = false
        reasons.push('Invoice is missing')
      }

      if (!invoicePriceEqualsAgreedRate) {
        isClosable = false
        reasons.push('Invoice total cost does not match the agreed rate')
      }

      if (!invoiceIsPaid) {
        isClosable = false
        reasons.push('Invoice is not paid')
      }

      return {
        isClosable: isClosable,
        reasons: reasons,
      };
    },
    async closeOrder(order_number) {
      try {
        await axios.put(`/order/close/${order_number}/`)
        await Swal.fire({
          icon: 'success',
          title: 'Order Closed',
        })
        this.$emit('performedOrderClosing')
        await this.$refs.table.getData()
      } catch {
        alert("Could not close this order")
      }
    },
    confirmOrderClose(order_number, order_child_type) {
      try {
        const getOrderTypeLabel = () => {
          return this.ORDER_CHILD_TYPES[order_child_type] ? this.ORDER_CHILD_TYPES[order_child_type].label || 'Order' : 'Order'
        }
        Swal.fire({
          position: "center",
          icon: "warning",
          title: `${getOrderTypeLabel()} ${order_number}`,
          html: `
    <p>Please review all order details carefully <br> before closing the order.</p>
    <p><strong>Note:</strong> Closed orders will be used for system-wide reporting and analysis.</p>
  `,
          showDenyButton: true,
          showConfirmButton: true,
          confirmButtonText: 'Proceed with Closure',
          denyButtonText: 'Cancel',
          cancelButtonColor: '#d33',
          confirmButtonColor: '#3085d6',
          denyButtonColor: '#757575',
          focusConfirm: false,
          inputLabel: `Please enter close-${order_number} to confirm order closure`,
          input: 'text',
          inputPlaceholder: 'close-' + order_number,
          inputValidator: (value) => {
            return new Promise((resolve) => {
              if (value.toLowerCase() === `close-${order_number}`) {
                resolve(this.closeOrder(order_number));
              } else {
                resolve('Order number mismatch. Please try again.');
              }
            });
          },
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        });
      } catch {
        alert("Technical error")
      }
    },
    setCloseableOrdersCount(count) {
      this.closeable_orders_count = count || 0
    },
  }
}
</script>

<template>

  <Table ref="table" v-bind="table" searchable per-page="25"
         class="mb-4" @on-loaded="onTableLoaded"
  >

    <template v-slot:top-right>
      <div class="d-flex flex-column align-items-end me-3">
        <h4 class="mb-0 fw-bold">
          {{ closeable_orders_count }} <small>ORDERS</small>
        </h4>
        <p class="mb-0 text-muted">Closable orders count</p>
      </div>
    </template>

    <template v-slot:header_div>
      <FilterByYearComponent :start_year="2022" :end_year="2025"/>
    </template>

    <template v-slot:order_number="{row: data}">
      <span v-if="ORDER_CHILD_TYPES[data.child_type]" class="badge badge-soft-secondary fs-12">
        <router-link :to="{name: ORDER_CHILD_TYPES[data.child_type].route_name, params: {id: data.order_number }}">
          {{ data.order_number }}
        </router-link>
      </span>
    </template>

    <template v-slot:child_type="{row: data}">
      {{ ORDER_CHILD_TYPES[data.child_type] ? ORDER_CHILD_TYPES[data.child_type].label || data.child_type : '???' }}
    </template>
    <template v-slot:position="{row: data}">
      <span class="text-capitalize">
        {{ POSITION[data.position] || '???' }}
      </span>
    </template>
    <template v-slot:type="{row: data}">
      <span class="text-capitalize">
        {{ data.type }}
      </span>
    </template>

    <template v-slot:quantity="{row: data}">
      <VTooltip>
        <span class="badge" :class="{
        'badge-outline-success' : data.filled_quantity === data.quantity,
        'badge-outline-danger' : data.filled_quantity < data.quantity,
      }">
          {{ data.filled_quantity + '/' + data.quantity }}
      </span>
        <template v-slot:popper>
          {{ data.filled_quantity || 0 }} out of {{ data.quantity || 0 }} {{
            (data.quantity || 0) > 1 ? 'rows' : 'row'
          }} filled
        </template>
      </VTooltip>
    </template>


    <template v-slot:company="{row: data}">
      <router-link v-if="data.company" class="d-flex gap-2"
                   :to="{name: 'customer_profile', params: {slug: data.company.slug ? data.company.slug : '56' }}">
        <div class="rounded-circle bg-soft-secondary text-secondary d-flex align-items-center justify-content-center"
             style="height: 20px; min-width: 20px !important;"
        >
          {{ (data.company.name.slice(0, 1)) }}
        </div>
        <span class="text-truncate" v-b-tooltip.hover :title="data.company.name">
            {{ (data.company.name) }}
          </span>
      </router-link>
    </template>

    <template v-slot:finance_status="{row: data}">
      <b-progress>
        <b-progress-bar v-for="(counterparty, index) of data.finance_status"
                        :key="index"
                        :value="100 / (data.finance_status.length)"
                        v-b-tooltip.hover
                        :title="counterparty.counterparty"
                        class="border-end"
                        :variant="counterparty.status ? 'success' : 'danger'"
        />
      </b-progress>
    </template>

    <template v-slot:total_agreed_rate="{row: data}">
      <div class="d-flex items-center gap-1 justify-content-center">
        $ {{
          parseFloat(data.total_agreed_rate || '0').toLocaleString(undefined, {
            minimumFractionDigits: 2, maximumFractionDigits: 2
          })
        }}
        <div v-if="data.invoice && (data.total_agreed_rate !== data.invoice.total_cost)"
             class="text-danger" v-b-tooltip.hover title="Invoice price differs from agreed rate"
        >
          ($ {{
            parseFloat(data.invoice.total_cost || '0').toLocaleString(undefined, {
              minimumFractionDigits: 2, maximumFractionDigits: 2
            })
          }})
        </div>
      </div>
    </template>


    <template v-slot:invoice="{row: data}">
      <div class="cursor-pointer text-decoration-underline" @click="downloadFile(data.invoice.file)"
           v-if="data.invoice">
        {{ data.invoice.number }}
      </div>
    </template>

    <template v-slot:user="{row: data}">
      {{ data.user ? data.user.username : '' }}
    </template>

    <template v-slot:status="{row: data}">
      <div v-if="data.is_closed" v-b-tooltip.hover :title="data.closeStatus.reasons_in_text_format">
        <span class="badge bg-success">CLOSED</span>
      </div>
      <div v-else class="text-truncate">
        <div v-if="user.role.toLowerCase() === 'admin' || (user.id === data.user.id)">
          <b-button @click="confirmOrderClose(data.order_number, data.child_type)"
                    v-if="data.closeStatus.isClosable"
                    variant="success"
                    size="sm"
          >
            Close
          </b-button>
          <div v-else class="text-danger text-truncate" v-b-tooltip.hover
               :title="data.closeStatus.reasons_in_text_format"
          >
            {{ data.closeStatus.reasons[0] }}
            <span>({{ data.closeStatus.reasons.length }})</span>
          </div>
        </div>
        <div v-else>
          <b-button v-b-tooltip.hover title="Order does not belong to you" size="sm" variant="light" disabled>Close
          </b-button>
        </div>
      </div>
    </template>
  </Table>
</template>

<style scoped>

</style>