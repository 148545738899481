<script setup>
import PageHeader from "@/components/page-header.vue";
import Filters from "@/views/pages/sales-reports/components/Filters.vue";
import RevenueByMonths from "@/views/pages/sales-reports/components/RevenueByMonths.vue";
import axios from "axios";
import {ref} from "vue";
import {useRoute} from "vue-router";

const route = useRoute();

const pageHeaderConfig = {
  title: 'Sales Report',
  items: [
    {
      text: "Home",
      href: "/",
    },
    {
      text: "Sales Report",
      active: true,
    },
  ],
}

let revenue_statistics = ref([])
let is_generating_report = ref(false)

const generateReport = async () => {
  is_generating_report.value = true
  try {
    const months = (route.query.months || '').split(',').filter(i => i !== '')
    console.log(months)
    let response = await axios.get('/order/sales_plan/', {
      params: {
        year: route.query.year,
        months: months.length > 0 ? months : undefined,
      }
    })
    revenue_statistics.value = response.data || []
  } catch {
    revenue_statistics.value = []
    alert("Error getting statistics");
  }
  is_generating_report.value = false
}
</script>

<template>
  <PageHeader v-bind="pageHeaderConfig"/>
  <Filters @generate-report="generateReport()" :is_generating_report="is_generating_report"/>
  <RevenueByMonths :statistics="revenue_statistics"/>
</template>

<style scoped>

</style>